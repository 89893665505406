import moment from "moment"
import { debounce } from "lodash"
import { useDispatch } from "react-redux"
import { setServerErrorPopup } from "../store/modules/config"
import { configureStore } from "../store"
import { AES, enc } from "crypto-js"

export function popupFn(text) {
  configureStore.dispatch(setServerErrorPopup({ open: true, text: text }))
}

// 전화번호에 - 붙이는 함수
export function phoneNumFormat(num, type) {
  if (num === null) return ""

  let formatNum = ""
  if (num.length === 11) {
    if (type === 0) {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-****-$3")
    } else {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
    }
  } else if (num.length === 8) {
    formatNum = num.replace(/(\d{4})(\d{4})/, "$1-$2")
  } else {
    if (num.indexOf("02") === 0) {
      if (type === 0) {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-****-$3")
      } else {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3")
      }
    } else {
      if (type === 0) {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-***-$3")
      } else {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
      }
    }
  }

  return formatNum
}
//누적시간 계산
export const timeFn = (time, type) => {
  if (type == "arr") {
    if (time == null || time == undefined) return []

    let temp = []
    let i = 0
    for (i = 0; i < time.length; i++) {
      let num1 = parseInt(time[i].split(":")[0])
      let num2 = parseInt(time[i].split(":")[1]) * 0.016
      temp = [...temp, num1 + num2]
    }
    // return temp
    return temp
  } else {
    if (time == null || time == undefined) return 0

    let num1 = parseInt(time.split(":")[0])
    let num2 = parseInt(time.split(":")[1]) * 0.016
    return num1 + num2
  }
}

// 날짜 포맷
export const dateFormat = day => {
  const TODAY = moment()
  // 작년
  if (moment(TODAY).format("YYYY") !== moment(day).format("YYYY")) return moment(day).format("YYYY.MM.DD")
  // 어제 이상(1일 이상경과)
  else if (moment(TODAY).subtract(1, "d").format("DD") !== moment(day).format("DD") && Math.abs(moment.duration(TODAY.diff(day)).asDays()) > 1)
    return moment(day).format("MM월 DD일")
  // 어제
  else if (moment(TODAY).subtract(1, "d").format("DD") === moment(day).format("DD")) return "어제"
  // 당일
  else {
    let hour = Number(moment(day).format("HH"))
    // 오후 12시
    if (hour === 12) return `오후 ${moment(day).format("HH:mm")}`
    // 오후
    else if (hour > 12) return `오후 ${hour - 12}:${moment(day).format("mm")}`
    // 오전
    else return `오전 ${moment(day).format("HH:mm")}`
  }
}

// input에 들어가는 전화번호 - 붙히기
export const formatInputPhone = num => {
  let formatNum = num + ""
  if (formatNum.length < 4) {
    formatNum = formatNum.substr(0, 3)
  } else if (formatNum.length < 8) {
    formatNum = formatNum.substr(0, 3) + "-" + formatNum.substr(3, 4)
  } else if (formatNum.length < 12) {
    formatNum = formatNum.substr(0, 3) + "-" + formatNum.substr(3, 4) + "-" + formatNum.substr(7, 4)
  }
  return formatNum
}

// 웹인지 앱인지 구분하는 녀석
export const isApp =
  typeof window !== "undefined" && (window.navigator.userAgent.includes("WISHRROM_Android") || window.navigator.userAgent.includes("WISHRROM_Ios"))

export const isIPhone = typeof window !== "undefined" && window.navigator.userAgent.includes("WISHRROM_Ios")

// 개발용 콘솔 로그
export const debug = data => {
  if (process.env.NODE_ENV === "development") {
    console.log(data)
  }
}

//
export function WidthToDP(px) {
  if (typeof window !== "undefined") {
    const screenWidth = window.innerWidth
    // 제플린 화면 넓이가 360 일 때
    return (screenWidth / 375) * px
  }
}
//데이터 체크 (데이터, setSatatus)
export function dataCheckFn(data, status = () => {}) {
  if (Object.keys(data).length > 0) {
    status(true)
    return true
  } else {
    status(false)
    return false
  }
}
// 안드로이드 백 핸들러
export const rnMessageListener = debounce((func, type) => {
  if (window.ReactNativeWebView) {
    let temp = {}

    document.addEventListener("message", e => {
      let data = JSON.parse(e.data)
      if (data.type == "BACK" && type == "BACK") func()
    })
  } else {
    console.log("!window.ReactNativeWebView")
  }
}, 500)

export const localStorgeSet = (key, value) => {
  let dataValue = AES.encrypt(value, process.env.SECURE_STORAGE_KEY)
  localStorage.setItem(key, dataValue)
}

export const localStorgeGet = key => {
  let data = localStorage.getItem(key)
  if (!data) return undefined
  let dataKey = AES.decrypt(data, process.env.SECURE_STORAGE_KEY)
  let decryptedText = ""
  try {
    decryptedText = dataKey.toString(enc.Utf8)
  } catch (e) {
    return (decryptedText = undefined)
  }

  return decryptedText
}
