import { createAction, handleActions } from "redux-actions"
import UserApis from "../../apis/UserApis"
import { localStorgeSet } from "../../utils/util"
import { setIsLogin } from "./sign"
/* action type */
const USER_INFO = "/user/USER_INFO"
const USER_SETTTING = "/user/USER_SETTTING"
const STUDY_LOCATIONS = "/user/STUDY_LOCATIONS"
const STUDY_GROUP = "/user/STUDY_GROUP"
const STUDY_BUDDIES = "/user/STUDY_BUDDIES"
const SELECT_STUDY_BUDDIES = "/user/SELECT_STUDY_BUDDIES"
const STUDY_BUDDIES_TOTAL = "/user/STUDY_BUDDIES_TOTAL"
const MESSAGE_IMAGES = "/user/MESSAGE_IMAGES"
const MESSAGE_LIST = "/user/MESSAGE_LIST"
const MESSAGE_LIST_TOTAL = "/user/MESSAGE_LIST_TOTAL"
const MESSAGE_DETAIL = "/user/MESSAGE_DETAIL"
const WITHDRAW_REASONS_LIST = "/user/WITHDRAW_REASONS_LIST"

/* action function */
export const setUserInfo = createAction(USER_INFO)
export const setStudyLocations = createAction(STUDY_LOCATIONS)
export const setStudyBuddies = createAction(STUDY_BUDDIES)
export const setStudyGroup = createAction(STUDY_GROUP)
export const setStudyBuddiesTotal = createAction(STUDY_BUDDIES_TOTAL)
export const setSelStudyBuddies = createAction(SELECT_STUDY_BUDDIES)
export const setMessageImages = createAction(MESSAGE_IMAGES)
export const setUserSetting = createAction(USER_SETTTING)
export const setMessageList = createAction(MESSAGE_LIST)
export const setMessageListTotal = createAction(MESSAGE_LIST_TOTAL)
export const setMessageDetail = createAction(MESSAGE_DETAIL)
export const setWithdrawReasons = createAction(WITHDRAW_REASONS_LIST)

const initState = {
  userInfo: {},
  studyLocations: [],
  studyBuddies: [],
  studyBuddiesTotal: 0,
  selectBuddies: [],
  messageImages: [],
  studyGroup: [],
  userSetting: {},
  messageList: [],
  messageListTotal: 0,
  messageDetail: {},
  withdrawReasonsList: [],
}

export default handleActions(
  {
    [USER_INFO]: (state, action) => ({
      ...state,
      userInfo: action.payload,
    }),
    [USER_INFO]: (state, action) => ({
      ...state,
      studyLocations: action.payload,
    }),
    [STUDY_BUDDIES]: (state, action) => ({
      ...state,
      studyBuddies: action.payload,
    }),
    [STUDY_BUDDIES_TOTAL]: (state, action) => ({
      ...state,
      studyBuddiesTotal: action.payload,
    }),
    [SELECT_STUDY_BUDDIES]: (state, action) => ({
      ...state,
      selectBuddies: action.payload,
    }),
    [MESSAGE_IMAGES]: (state, action) => ({
      ...state,
      messageImages: action.payload,
    }),
    [STUDY_GROUP]: (state, action) => ({
      ...state,
      studyGroup: action.payload,
    }),
    [USER_SETTTING]: (state, action) => ({
      ...state,
      userSetting: action.payload,
    }),
    [MESSAGE_LIST]: (state, action) => ({
      ...state,
      messageList: action.payload,
    }),
    [MESSAGE_LIST_TOTAL]: (state, action) => ({
      ...state,
      messageListTotal: action.payload,
    }),
    [MESSAGE_DETAIL]: (state, action) => ({
      ...state,
      messageDetail: action.payload,
    }),
    [WITHDRAW_REASONS_LIST]: (state, action) => ({
      ...state,
      withdrawReasonsList: action.payload,
    }),
  },
  initState
)
/* action api function */

// 학습자 그룸 리스트
export const getUserStudentGroupsAction = param => async dispatch => {
  const result = await UserApis.getUserStudentGroups(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setStudyGroup(result.data.studentGroups))
    return true
  } else return false
}

//학습자 그룹 추가
export const postUserStudentGroupAction = param => async dispatch => {
  const result = await UserApis.postUserStudentGroup(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
//이메일 추가
export const postUserEmailsAction = param => async dispatch => {
  const result = await UserApis.postUserEmails(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

//공부장소 추가
export const postUserStudyLocationAction = param => async dispatch => {
  const result = await UserApis.postUserStudyLocation(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

//공부장소 수정
export const putUserStudyLocationAction = param => async dispatch => {
  const result = await UserApis.putUserStudyLocation(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

//공부장소 삭제
export const delUserStudyLocationAction = param => async dispatch => {
  const result = await UserApis.delUserStudyLocation(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

//스크린 닉네임 추가
export const postUserNicknameAction = param => async dispatch => {
  const result = await UserApis.postUserNickname(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
//스크린 닉네임 추가
export const postUserNicknameCheckAction = param => async dispatch => {
  const result = await UserApis.postUserNicknameCheck(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

// 스터디 친구 목록
export const getUserStudyBuddiesAction = param => async dispatch => {
  const result = await UserApis.getUserStudyBuddies(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setStudyBuddies(result.data.studyBuddies))
    dispatch(setStudyBuddiesTotal(result.data.totalCount))
    return true
  } else {
    dispatch(setStudyBuddies([]))
    return false
  }
}
// 스터디 친구 목록 add
export const getUserStudyBuddiesAddAction = param => async dispatch => {
  const result = await UserApis.getUserStudyBuddies(param)
  if (result.code == 0 || result.code == 1) {
    let temp = param.studyBuddies.length != 0 ? param.studyBuddies : []
    dispatch(setStudyBuddies([...temp, ...result.data.studyBuddies]))
    dispatch(setStudyBuddiesTotal(result.data.totalCount))
    return true
  } else {
    dispatch(setStudyBuddies([]))
    return false
  }
}
//스터디 친구 추가
export const postUserStudyBuddyAction = param => async dispatch => {
  const result = await UserApis.postUserStudyBuddy(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
//스터디 친구 삭제
export const delUserStudyBuddyAction = param => async dispatch => {
  const result = await UserApis.delUserStudyBuddy(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
//메세지 삭제
export const delMessagesAction = param => async dispatch => {
  const result = await UserApis.delMessages(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

// 메세지 이미지 목록
export const getMessgaeImagesAction = param => async dispatch => {
  const result = await UserApis.getMessgaeImages(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setMessageImages(result.data.messageImages))
    return true
  } else {
    dispatch(setMessageImages([]))
    return false
  }
}

// 메세지 보내기
export const postMessageSendAction = param => async dispatch => {
  const result = await UserApis.postMessageSend(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

//내 스크린 닉네임 수정
export const putUserNikcknameAction = param => async dispatch => {
  const result = await UserApis.putUserNikckname(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
//내 프로필 이미지 수정
export const putUserProfileImageAction = param => async dispatch => {
  const result = await UserApis.putUserProfileImage(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
//파일 업로드 단일
export const postUploadFileAction = param => async dispatch => {
  const result = await UserApis.postUploadFile(param)
  if (result.code == 0 || result.code == 1) {
    return result.data.file
  } else return false
}
// 내세팅
export const getMySettingAction = param => async dispatch => {
  const result = await UserApis.getUserProfileMySetting(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setUserSetting(result.data.setting))
    return true
  } else {
    dispatch(setUserSetting([]))
    return false
  }
}

//메세지 목록
export const getMessageAction = param => async dispatch => {
  let result = false
  if (param.type == 0) {
    result = await UserApis.getMessageReceive(param)
  } else if (param.type == 1) {
    result = await UserApis.getMessageSend(param)
  }
  if (result.code == 0 || result.code == 1) {
    if (param.type == 0) {
      dispatch(setMessageList(result.data.receiveMessages))
    } else if (param.type == 1) {
      dispatch(setMessageList(result.data.sendMessages))
    }
    dispatch(setMessageListTotal(result.data.totalCount))
    return true
  } else {
    dispatch(setMessageList([]))
    dispatch(setMessageListTotal(0))
    return false
  }
}

//메세지 목록 추가
export const getMessageAddAction = param => async dispatch => {
  let result = false
  if (param.type == 1) {
    result = await UserApis.getMessageSend(param)
  } else if (param.type == 0) {
    result = await UserApis.getMessageReceive(param)
  }
  if (result.code == 0 || result.code == 1) {
    let temp = param.messageList.length != 0 ? param.messageList : []
    if (param.type == 0) {
      dispatch(setMessageList([...temp, ...result.data.receiveMessages]))
    } else if (param.type == 1) {
      dispatch(setMessageList([...temp, ...result.data.sendMessages]))
    }
    dispatch(setMessageListTotal(result.data.totalCount))
    return true
  } else {
    dispatch(setMessageList([]))
    dispatch(setMessageListTotal(0))
    return false
  }
}

//메세지 상세
export const getMessageDetailAction = param => async dispatch => {
  let result = false
  result = await UserApis.getMessageDetail(param)

  if (result.code == 0 || result.code == 1) {
    dispatch(setMessageDetail(result.data.message))
    return true
  } else {
    dispatch(setMessageDetail({}))
    return false
  }
}

//이메일 수정 인증 이메일 보내기
export const postAuthEmailAction = param => async dispatch => {
  const result = await UserApis.postAuthEmail(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

//이메일 수정 인증
export const putAuthEmailAction = param => async dispatch => {
  const result = await UserApis.putAuthEmail(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

//이메일 수정 인증
export const putUserProFileEmailAction = param => async dispatch => {
  const result = await UserApis.putUserProFileEmail(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

//핸드폰 수정
export const putUserProfliePhoneAction = param => async dispatch => {
  const result = await UserApis.putUserProfliePhone(param)
  if (result.code == 0 || result.code == 1) {
    if (window.ReactNativeWebView) {
      localStorgeSet(process.env.TOKEN_ID, result.data.accessToken)
    } else {
      sessionStorage.setItem(process.env.TOKEN_ID, result.data.accessToken)
    }

    localStorgeSet("loginUserSeq", result.data.loginUserSeq)
    dispatch(setIsLogin(true))
    return true
  } else return false
}

//탈퇴사유 리스트
export const getWithdrawReasonsAction = param => async dispatch => {
  let result = false
  result = await UserApis.getWithdrawReasons(param)

  if (result.code == 0 || result.code == 1) {
    dispatch(setWithdrawReasons(result.data.withdrawReasons))
    return true
  } else {
    dispatch(setWithdrawReasons([]))
    return false
  }
}

//내 스터디 콘텐츠 공개 설정
export const putUserSettingsContentAction = param => async dispatch => {
  const result = await UserApis.putUserSettingsContent(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

//내 컴퓨터 초기 장치 설정
export const putUserSettingGearAction = param => async dispatch => {
  const result = await UserApis.putUserSettingGear(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

//탈퇴
export const putWithdrawAction = param => async dispatch => {
  const result = await UserApis.putWithdraw(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

//subscriptions 컨설던트 스터디 투두 신청
export const postSubscriptionsAction = param => async dispatch => {
  const result = await UserApis.postSubscriptions(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

//subscriptions 컨설던트 상담신청 신청
export const postConsultingsAction = param => async dispatch => {
  const result = await UserApis.postConsultings(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
//친구 추천 설정
export const putUserSettingRecommendationAction = param => async dispatch => {
  const result = await UserApis.putUserSettingRecommendation(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
//내 알림 설정
export const putUserSettingAlarmAction = param => async dispatch => {
  const result = await UserApis.putUserSettingAlarm(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
//내 약관 설정
export const putUserSettingTermAction = param => async dispatch => {
  const result = await UserApis.putUserSettingTerm(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
