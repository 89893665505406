import { createAction, handleActions } from "redux-actions"

/* action type */
export const SET_LOADING_POPUP = "@boilerplate/config/SET_LOADING_POPUP"
export const SET_SERVER_ERROR_POPUP = "@boilerplate/config/SET_SERVER_ERROR_POPUP"

/* action function */
export const setLoadingPopup = createAction(SET_LOADING_POPUP)
export const setServerErrorPopup = createAction(SET_SERVER_ERROR_POPUP)

// default state value
const initialState = {
  loadingPopup: false,
  serverErrorPopup: { open: false, text: "" },
}

// handle action
export default handleActions(
  {
    [SET_LOADING_POPUP]: (state, action) => ({
      ...state,
      loadingPopup: action.payload,
    }),
    [SET_SERVER_ERROR_POPUP]: (state, action) => ({
      ...state,
      serverErrorPopup: action.payload,
    }),
  },
  initialState
)
