import { navigate } from "gatsby-link"
import { createAction, handleActions } from "redux-actions"
import SignApis from "../../apis/SignApis"
import { localStorgeSet } from "../../utils/util"
import { setLoginPopup } from "./popup"

const SET_IS_LOGIN = "sign/SET_IS_LOGIN"
const USER_INFO = "sign/USER_INFO"
const TERM = "sign/TERM"
const USER_AUTH_LOG_SEQ = "sign/USER_AUTH_LOG_SEQ"
const USER_PHONE_NUM = "sign/USER_PHONE_NUM"
const USER_STUDY_LOCATIONS = "sign/USER_STUDY_LOCATIONS"
const AUTH_EMAIL_SEQ = "sign/AUTH_EMAIL_SEQ"

export const setIsLogin = createAction(SET_IS_LOGIN)

export const setUserInfo = createAction(USER_INFO)
export const getTerm = createAction(TERM)
export const setUserAuthLogSeq = createAction(USER_AUTH_LOG_SEQ)
export const setUserPhoneNum = createAction(USER_PHONE_NUM)
export const setStudylocations = createAction(USER_STUDY_LOCATIONS)
export const setAuthEmailSeq = createAction(AUTH_EMAIL_SEQ)

const initState = {
  isLogin: false,
  term: {},
  userAuthLogSeq: null,
  userStudyLocations: [],
  userPhoneNum: null,
  userInfo: {},
  authEmailSeq: {},
}

export default handleActions(
  {
    [SET_IS_LOGIN]: (state, action) => ({
      ...state,
      isLogin: action.payload,
    }),
    [TERM]: (state, action) => ({
      ...state,
      term: action.payload,
    }),
    [USER_AUTH_LOG_SEQ]: (state, action) => ({
      ...state,
      userAuthLogSeq: action.payload,
    }),
    [USER_STUDY_LOCATIONS]: (state, action) => ({
      ...state,
      userStudyLocations: action.payload,
    }),
    [USER_PHONE_NUM]: (state, action) => ({
      ...state,
      userPhoneNum: action.payload,
    }),
    [USER_INFO]: (state, action) => ({
      ...state,
      userInfo: action.payload,
    }),
    [AUTH_EMAIL_SEQ]: (state, action) => ({
      ...state,
      authEmailSeq: action.payload,
    }),
  },
  initState
)
/* action api function */

// 회원가입
export const postSignUp = param => async dispatch => {
  const result = await SignApis.postSignUp(param)
  if (result.code == 0 || result.code == 1) {
    if (window.ReactNativeWebView) {
      localStorgeSet(process.env.TOKEN_ID, result.data.accessToken)
    } else {
      sessionStorage.setItem(process.env.TOKEN_ID, result.data.accessToken)
    }

    localStorgeSet("loginUserSeq", result.data.loginUserSeq)
    dispatch(setIsLogin(true))

    //인증때 쓴 정보 지우기
    dispatch(setUserPhoneNum(null))
    dispatch(setUserAuthLogSeq(null))
    return true
  } else return false
}
// new 회원가입
export const postSignUpV2 = param => async dispatch => {
  const result = await SignApis.postSignUpV2(param)
  if (result.code == 0 || result.code == 1) {
    if (window.ReactNativeWebView) {
      localStorgeSet(process.env.TOKEN_ID, result.data.accessToken)
    } else {
      sessionStorage.setItem(process.env.TOKEN_ID, result.data.accessToken)
    }

    localStorgeSet("loginUserSeq", result.data.loginUserSeq)
    dispatch(setIsLogin(true))

    //인증때 쓴 정보 지우기
    dispatch(setUserPhoneNum(null))
    dispatch(setUserAuthLogSeq(null))
    dispatch(setAuthEmailSeq({}))
    return true
  } else return false
}

///new signup 회원가입용 소셜 or 이메일 인증
export const postSignupDatasAction = param => async dispatch => {
  const result = await SignApis.postSignupDatas(param)
  if (result.code == 0) {
    return result.data
  } else return false
}

///new signup 회원가입용 이메일 인증하기
export const putSignupDatasAction = param => async dispatch => {
  const result = await SignApis.putSignupDatas(param)
  if (result.code == 0) {
    return result.data
  } else return false
}

// 로그인
export const postSignIn = param => async dispatch => {
  const result = await SignApis.postSignIn(param)
  if (result.code == 0 || result.code == 1) {
    if (window.ReactNativeWebView) {
      localStorgeSet(process.env.TOKEN_ID, result.data.accessToken)
    } else {
      sessionStorage.setItem(process.env.TOKEN_ID, result.data.accessToken)
    }

    localStorgeSet("loginUserSeq", result.data.loginUserSeq)
    dispatch(setIsLogin(true))

    return true
  } else return false
}
// 로그인 상태 체크 Exploring
export const checkSignStatusExploring = param => async dispatch => {
  const result = await SignApis.checkSignStatus(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setIsLogin(true))
    dispatch(setUserInfo(result.data.user))
    return true
  } else return false
}
// 로그인 상태 체크
export const checkSignStatus = param => async dispatch => {
  const result = await SignApis.checkSignStatus(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setIsLogin(true))
    dispatch(setUserInfo(result.data.user))
    return true
  } else {
    navigate("/exploring")
    dispatch(setLoginPopup(true))
    return false
  }
}
// 로그아웃
export const postSignOut = param => async dispatch => {
  const result = await SignApis.postSignOut(param)
  if (result.code == 0 || result.code == 1) {
    if (window.ReactNativeWebView) {
      localStorage.removeItem(process.env.TOKEN_ID)
    } else {
      sessionStorage.removeItem(process.env.TOKEN_ID)
    }

    localStorage.removeItem("loginUserSeq")
    localStorage.removeItem("isVisited")
    dispatch(setIsLogin(false))
    dispatch(setUserInfo({}))
    return true
  } else return false
}
//약관
export const getTermAction = param => async dispatch => {
  const result = await SignApis.getTerm(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(getTerm(result.data))
    return true
  } else return false
}
// 인증번호 요청
export const postAuthNumbertAction = param => async dispatch => {
  const result = await SignApis.postAuthNumber(param)
  if (result.code == 0 || result.code == 1) {
    // dispatch(setUserAuthLogSeq(result.data.userAuthLogSeq))
    return result.data
  } else return false
}
// 인증번호 인증 확인
export const postAuthNumberCheckAction = param => async dispatch => {
  const result = await SignApis.postAuthNumberCheck(param)
  if (result.code == 0 || result.code == 1) {
    return result.data.authResult
  } else return false
}
// 공부장소 리스트
export const postGetStudyLocationsAction = param => async dispatch => {
  const result = await SignApis.postGetStudyLocations(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setStudylocations(result.data.studyLocations))
    return true
  } else {
    dispatch(setStudylocations([]))
    return false
  }
}
// 공부장소 리스트 인증(로그인)
export const postStudyLocationsAction = param => async dispatch => {
  const result = await SignApis.postStudyLocations(param)
  if (result.code == 0 || result.code == 1) {
    if (window.ReactNativeWebView) {
      localStorgeSet(process.env.TOKEN_ID, result.data.accessToken)
    } else {
      sessionStorage.setItem(process.env.TOKEN_ID, result.data.accessToken)
    }

    localStorgeSet("loginUserSeq", result.data.loginUserSeq)
    dispatch(setIsLogin(true))
    // dispatch(setUserInfo(result.data.user))

    //인증때 쓴 정보 지우기
    dispatch(setUserPhoneNum(null))
    dispatch(setUserAuthLogSeq(null))
    return true
  } else return false
}
//로그인 파트너
export const putPartnerAuthAction = param => async dispatch => {
  const result = await SignApis.putPartnerAuth(param)
  if (result.code == 0 || result.code == 1) {
    if (window.ReactNativeWebView) {
      localStorgeSet(process.env.TOKEN_ID, result.data.accessToken)
    } else {
      sessionStorage.setItem(process.env.TOKEN_ID, result.data.accessToken)
    }

    localStorgeSet("loginUserSeq", result.data.loginUserSeq)
    dispatch(setIsLogin(true))
    // dispatch(setUserInfo(result.data.user))

    //인증때 쓴 정보 지우기
    dispatch(setUserPhoneNum(null))
    dispatch(setUserAuthLogSeq(null))
    return true
  } else return false
}
//인증번호요청 파트너
export const postPartnerAuthNumberAction = param => async dispatch => {
  const result = await SignApis.postPartnerAuthNumber(param)
  if (result.code == 0 || result.code == 1) {
    return result.data
  } else return false
}
//비번찾기 이메일
export const postPasswordFindAction = param => async dispatch => {
  const result = await SignApis.postPasswordFind(param)
  if (result.code == 0 || result.code == 1) {
    return result.data.result
  } else return false
}
//비번찾기 인증번호
export const putPasswordFindAction = param => async dispatch => {
  const result = await SignApis.putPasswordFind(param)
  if (result.code == 0 || result.code == 1) {
    return result.data.result
  } else return false
}
//비번찾기 변경
export const putPasswordResetAction = param => async dispatch => {
  const result = await SignApis.putPasswordReset(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
