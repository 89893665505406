import { createAction, handleActions } from "redux-actions"

const SET_SIDE_POPUP = "popup/set-side-popup"
const SET_INTRO_POPUP = "popup/set-intro-popup"
const SET_GATEWAY_POPUP = "popup/set-gateway-popup"
const SET_LOGIN_POPUP = "popup/set-login-popup"
const SET_LOGIN_PARTNER_POPUP = "popup/set-login-partner-popup"
const SET_SIGN_UP_POPUP = "popup/set-sign-up-popup"
const SET_SIGN_UP_POPUP_SETTING = "popup/set-sign-up-popup-setting"
const SET_TERMS_POPUP = "popup/set-terms-popup"
const SET_RANKING_POPUP = "popup/set-ranking-popup"
const SET_ZIPCODE_POPUP = "popup/set-zipcode-popup"
const SET_PROFILE_POPUP = "popup/set-profile-popup"
const SET_CONSULTANT_PROFILE_POPUP = "popup/set-consultant-profile-popup"
const SET_MAKE_TO_DO_POPUP = "popup/set-make-to-do-popup"
const SET_EDIT_TO_DO_POPUP = "popup/set-edit-to-do-popup"
const SET_STUDY_INFO_POPUP = "popup/set-study-info-popup"
const SET_PW_FIND_POPUP = "popup/set-pw-find-popup"

//스터디만들기
const SET_STUDY_MAKE_POPUP = "popup/set-study-make-popup"
const SET_STUDY_MAKE_FREE_POPUP = "popup/set-study-make-free-popup"
const SET_STUDY_MAKE_INFO_POPUP = "popup/set-study-make-info-popup"
const SET_STUDY_MAKE_PW_POPUP = "popup/set-study-make-pw-popup"
const SET_STUDY_MAKE_PW_SETTING_POPUP = "popup/set-study-make-pw-setting-popup"
const SET_STUDY_MAKE_SCHEDULE_POPUP = "popup/set-study-make-schedule-popup"
const SET_STUDY_MAKE_NAME_POPUP = "popup/set-study-make-name-popup"
const SET_STUDY_MAKE_RESULT_POPUP = "popup/set-study-make-result-popup"
const SET_STUDY_MAKE_SETTING_POPUP = "popup/set-study-make-setting-popup"
const SET_STUDY_MAKE_SETTING_SCHEDULE_POPUP = "popup/set-study-make-setting-schedule-popup"
const SET_STUDY_MAKE_REFERRAL_POPUP = "popup/set-study-Referral-popup"
const SET_STUDY_MAKE_SCHEDULE_DETAIL_POPUP = "popup/set-study-make-schedule-Detail-popup"
const SET_STUDY_MAKE_SCHEDULE_DETAIL_SET_POPUP = "popup/set-study-make-schedule-Detail-set-popup"
const SET_STUDY_MAKE_SCHEDULE_DETAIL_TIME_POPUP = "popup/set-study-make-schedule-Detail-time-popup"
const SET_STUDY_MAKE_JOIN_MESSAGE_POPUP = "popup/set-study-make-join-message-popup"
const SET_STUDY_MAKE_JOIN_MESSAGE_INPUT_POPUP = "popup/set-study-make-join-message-input-popup"
const SET_STUDY_MAKE_REWARD_POINT_POPUP = "popup/set-study-make-reward-point-popup"
const SET_STUDY_MAKE_AI_SETTING_POPUP = "popup/set-study-make-ai-setting-popup"
const SET_STUDY_MAKE_LIMIT_JOIN_POPUP = "popup/set-study-make-limit-join-popup"
const SET_STUDY_MAKE_GEAR_POPUP = "popup/set-study-make-gear-popup"
//초대하기
const SET_INVITE_POPUP = "popup/set-invite-popup"

//내프로필 편집
const SET_EDIT_MY_PROFILE_POPUP = "popup/set-edit-myProfile-popup"

//마이렛미업 장소추가
const SET_ADD_STUDY_LOCATION_POPUP = "popup/set-add-study-location-popup"

//jitsi
const SET_JITSI_POPUP = "popup/set-jitsi-popup"
const SET_JITSI_CLOSE = "popup/set-jitsi-close"
const SET_BRAINWAVE_POPUP = "popup/set-brainwave-popup"

//투두 전체보기
const SET_TODO_TOTAL_POPUP = "popup/set-todo-total-popup"

export const setSidePopup = createAction(SET_SIDE_POPUP)
export const setIntroPopup = createAction(SET_INTRO_POPUP)
export const setGatewayPopup = createAction(SET_GATEWAY_POPUP)
export const setLoginPopup = createAction(SET_LOGIN_POPUP)
export const setLoginPartnerPopup = createAction(SET_LOGIN_PARTNER_POPUP)
export const setSignUpPopup = createAction(SET_SIGN_UP_POPUP)
export const setSignUpSettingPopup = createAction(SET_SIGN_UP_POPUP_SETTING)
export const setTermsPopup = createAction(SET_TERMS_POPUP)
export const setRankingPopup = createAction(SET_RANKING_POPUP)
export const setZipCodePopup = createAction(SET_ZIPCODE_POPUP)
export const setProfilePopup = createAction(SET_PROFILE_POPUP)
export const setConsultantProfilePopup = createAction(SET_CONSULTANT_PROFILE_POPUP)
export const setMakeToDoPopup = createAction(SET_MAKE_TO_DO_POPUP)
export const setEditToDoPopup = createAction(SET_EDIT_TO_DO_POPUP)
export const setStudyInfoPopup = createAction(SET_STUDY_INFO_POPUP)
export const setPwFindPopup = createAction(SET_PW_FIND_POPUP)

//스터디만들기
export const setStudyMakePopup = createAction(SET_STUDY_MAKE_POPUP)
export const setStudyMakeFreePopup = createAction(SET_STUDY_MAKE_FREE_POPUP)
export const setStudyMakeInfoPopup = createAction(SET_STUDY_MAKE_INFO_POPUP)
export const setStudyMakePwPopup = createAction(SET_STUDY_MAKE_PW_POPUP)
export const setStudyMakePwSettingPopup = createAction(SET_STUDY_MAKE_PW_SETTING_POPUP)
export const setStudyMakeNamePopup = createAction(SET_STUDY_MAKE_NAME_POPUP)
export const setStudyMakeResultPopup = createAction(SET_STUDY_MAKE_RESULT_POPUP)
export const setStudyMakeSettingPopup = createAction(SET_STUDY_MAKE_SETTING_POPUP)
export const setStudyMakeSettingSchedulePopup = createAction(SET_STUDY_MAKE_SETTING_SCHEDULE_POPUP)
export const setStudyMakeSchedulePopup = createAction(SET_STUDY_MAKE_SCHEDULE_POPUP)
export const setStudyMakeReferralPopup = createAction(SET_STUDY_MAKE_REFERRAL_POPUP)
export const setStudyMakeScheduleDetailPopup = createAction(SET_STUDY_MAKE_SCHEDULE_DETAIL_POPUP)
export const setStudyMakeScheduleDetailPopupset = createAction(SET_STUDY_MAKE_SCHEDULE_DETAIL_SET_POPUP)
export const setStudyMakeScheduleDetailTimePopup = createAction(SET_STUDY_MAKE_SCHEDULE_DETAIL_TIME_POPUP)
export const setStudyMakeJoinmessagePopup = createAction(SET_STUDY_MAKE_JOIN_MESSAGE_POPUP)
export const setStudyMakeJoinmessageInputPopup = createAction(SET_STUDY_MAKE_JOIN_MESSAGE_INPUT_POPUP)
export const setStudyMakeRewardPointPopup = createAction(SET_STUDY_MAKE_REWARD_POINT_POPUP)
export const setStudyMakeAISettingPopup = createAction(SET_STUDY_MAKE_AI_SETTING_POPUP)
export const setStudyMakeLimitJoingPopup = createAction(SET_STUDY_MAKE_LIMIT_JOIN_POPUP)
export const setStudyMakeGearPopup = createAction(SET_STUDY_MAKE_GEAR_POPUP)

//초대하기
export const setInvitePopup = createAction(SET_INVITE_POPUP)

//내프로필 편집
export const setEditMyProfilePopup = createAction(SET_EDIT_MY_PROFILE_POPUP)
//마이렛미업 장소추가
export const setAddStudyLocationPopup = createAction(SET_ADD_STUDY_LOCATION_POPUP)

//jitsi
export const setJitsiPopup = createAction(SET_JITSI_POPUP)
export const setJitsiClose = createAction(SET_JITSI_CLOSE)
//barinwave
export const setBrainwavePopup = createAction(SET_BRAINWAVE_POPUP)

//투두 전체보기
export const setTodoTotalPopup = createAction(SET_TODO_TOTAL_POPUP)

const initState = {
  sidePopup: false,
  introPopup: false,
  gatewayPopup: false,
  loginPopup: false,
  loginPartnerPopup: false,
  signUpPopup: false,
  signUpSettingPopup: { open: false, data: 0 },
  termsPopup: { open: false, type: 0 },
  rankingPopup: false,
  zipCodePopup: false,
  profilePopup: { open: false, data: {} },
  consultantProfilePopup: { open: false, data: {} },
  makeToDoPopup: { open: false, data: {} },
  editToDoPopup: { open: false, data: {} },
  studyInfoPopup: { open: false, data: {} },
  pwFindPopup: false,

  studyMakePopup: false,
  studyMakeFreePopup: false,
  studyMakePopupInfo: { open: false, type: 0 },
  studyMakePopupPw: false,
  studyMakePopupName: false,
  studyMakePopupResult: false,
  studyMakePopupSetting: { open: false, data: {} },
  studyMakeSchedule: false,
  studyMakeScheduleDetail: false,
  studyMakeReferral: { open: false, data: {} },
  studyMakeScheduleDetailTime: false,
  studyMakeJoinMessage: false,
  studyMakeJoinMessageInput: false,
  studyMakeRewardPointPopup: false,
  studyMakeAiSettingPopup: false,
  studyLimitJoinPopup: false,
  studyMakeGearPopup: false,
  studyMakePopupPwSet: false,
  studyMakeSettingSchedulePopup: false,

  invitePopup: false,

  editMyProfile: false,
  addStudyLocation: false,

  jitsiPopup: false,
  jitsiClose: false,

  brainwavePopup: { open: false, data: { seq: "", url: "" } },

  todoTatalPopup: false,
}

export default handleActions(
  {
    [SET_SIDE_POPUP]: (state, action) => ({
      ...state,
      sidePopup: action.payload,
    }),
    [SET_INTRO_POPUP]: (state, action) => ({
      ...state,
      introPopup: action.payload,
    }),
    [SET_GATEWAY_POPUP]: (state, action) => ({
      ...state,
      gatewayPopup: action.payload,
    }),
    [SET_LOGIN_POPUP]: (state, action) => ({
      ...state,
      loginPopup: action.payload,
    }),
    [SET_LOGIN_PARTNER_POPUP]: (state, action) => ({
      ...state,
      loginPartnerPopup: action.payload,
    }),
    [SET_SIGN_UP_POPUP]: (state, action) => ({
      ...state,
      signUpPopup: action.payload,
    }),
    [SET_SIGN_UP_POPUP_SETTING]: (state, action) => ({
      ...state,
      signUpSettingPopup: action.payload,
    }),
    [SET_TERMS_POPUP]: (state, action) => ({
      ...state,
      termsPopup: action.payload,
    }),
    [SET_RANKING_POPUP]: (state, action) => ({
      ...state,
      rankingPopup: action.payload,
    }),
    [SET_ZIPCODE_POPUP]: (state, action) => ({
      ...state,
      zipCodePopup: action.payload,
    }),
    [SET_PROFILE_POPUP]: (state, action) => ({
      ...state,
      profilePopup: action.payload,
    }),
    [SET_CONSULTANT_PROFILE_POPUP]: (state, action) => ({
      ...state,
      consultantProfilePopup: action.payload,
    }),
    [SET_MAKE_TO_DO_POPUP]: (state, action) => ({
      ...state,
      makeToDoPopup: action.payload,
    }),
    [SET_EDIT_TO_DO_POPUP]: (state, action) => ({
      ...state,
      editToDoPopup: action.payload,
    }),
    [SET_STUDY_INFO_POPUP]: (state, action) => ({
      ...state,
      studyInfoPopup: action.payload,
    }),

    [SET_STUDY_MAKE_POPUP]: (state, action) => ({
      ...state,
      studyMakePopup: action.payload,
    }),
    [SET_STUDY_MAKE_FREE_POPUP]: (state, action) => ({
      ...state,
      studyMakeFreePopup: action.payload,
    }),
    [SET_STUDY_MAKE_INFO_POPUP]: (state, action) => ({
      ...state,
      studyMakePopupInfo: action.payload,
    }),
    [SET_STUDY_MAKE_PW_POPUP]: (state, action) => ({
      ...state,
      studyMakePopupPw: action.payload,
    }),
    [SET_STUDY_MAKE_PW_SETTING_POPUP]: (state, action) => ({
      ...state,
      studyMakePopupPwSet: action.payload,
    }),
    [SET_STUDY_MAKE_NAME_POPUP]: (state, action) => ({
      ...state,
      studyMakePopupName: action.payload,
    }),
    [SET_STUDY_MAKE_RESULT_POPUP]: (state, action) => ({
      ...state,
      studyMakePopupResult: action.payload,
    }),
    [SET_STUDY_MAKE_SETTING_POPUP]: (state, action) => ({
      ...state,
      studyMakePopupSetting: action.payload,
    }),
    [SET_INVITE_POPUP]: (state, action) => ({
      ...state,
      invitePopup: action.payload,
    }),
    [SET_JITSI_POPUP]: (state, action) => ({
      ...state,
      jitsiPopup: action.payload,
    }),
    [SET_JITSI_CLOSE]: (state, action) => ({
      ...state,
      jitsiClose: action.payload,
    }),
    [SET_STUDY_MAKE_SCHEDULE_POPUP]: (state, action) => ({
      ...state,
      studyMakeSchedule: action.payload,
    }),
    [SET_STUDY_MAKE_REFERRAL_POPUP]: (state, action) => ({
      ...state,
      studyMakeReferral: action.payload,
    }),
    [SET_STUDY_MAKE_SCHEDULE_DETAIL_POPUP]: (state, action) => ({
      ...state,
      studyMakeScheduleDetail: action.payload,
    }),
    [SET_STUDY_MAKE_SCHEDULE_DETAIL_SET_POPUP]: (state, action) => ({
      ...state,
      studyMakeScheduleDetailset: action.payload,
    }),
    [SET_STUDY_MAKE_SCHEDULE_DETAIL_TIME_POPUP]: (state, action) => ({
      ...state,
      studyMakeScheduleDetailTime: action.payload,
    }),
    [SET_STUDY_MAKE_JOIN_MESSAGE_POPUP]: (state, action) => ({
      ...state,
      studyMakeJoinMessage: action.payload,
    }),
    [SET_STUDY_MAKE_JOIN_MESSAGE_INPUT_POPUP]: (state, action) => ({
      ...state,
      studyMakeJoinMessageInput: action.payload,
    }),
    [SET_EDIT_MY_PROFILE_POPUP]: (state, action) => ({
      ...state,
      editMyProfile: action.payload,
    }),
    [SET_ADD_STUDY_LOCATION_POPUP]: (state, action) => ({
      ...state,
      addStudyLocation: action.payload,
    }),
    [SET_TODO_TOTAL_POPUP]: (state, action) => ({
      ...state,
      todoTatalPopup: action.payload,
    }),
    [SET_STUDY_MAKE_REWARD_POINT_POPUP]: (state, action) => ({
      ...state,
      studyMakeRewardPointPopup: action.payload,
    }),
    [SET_STUDY_MAKE_AI_SETTING_POPUP]: (state, action) => ({
      ...state,
      studyMakeAiSettingPopup: action.payload,
    }),
    [SET_STUDY_MAKE_LIMIT_JOIN_POPUP]: (state, action) => ({
      ...state,
      studyLimitJoinPopup: action.payload,
    }),
    [SET_STUDY_MAKE_GEAR_POPUP]: (state, action) => ({
      ...state,
      studyMakeGearPopup: action.payload,
    }),
    [SET_STUDY_MAKE_SETTING_SCHEDULE_POPUP]: (state, action) => ({
      ...state,
      studyMakeSettingSchedulePopup: action.payload,
    }),
    [SET_BRAINWAVE_POPUP]: (state, action) => ({
      ...state,
      brainwavePopup: action.payload,
    }),
    [SET_PW_FIND_POPUP]: (state, action) => ({
      ...state,
      pwFindPopup: action.payload,
    }),
  },
  initState
)
