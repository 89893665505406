import { createAction, handleActions } from "redux-actions"
import ExploringApis from "../../apis/ExploringApis"

/* action type */
const STUDY_HOME = "/exploring/STUDY_HOME"

/* action function */
export const setStudyHome = createAction(STUDY_HOME)

const initState = {
  exploringData: {},
}

export default handleActions(
  {
    [STUDY_HOME]: (state, action) => ({
      ...state,
      exploringData: action.payload,
    }),
  },
  initState
)
/* action api function */

// 스터디 탐색
export const getStudyhomeAction = param => async dispatch => {
  const result = await ExploringApis.getStudyhome(param)
  if (result.code == 0) {
    dispatch(setStudyHome(result.data))
    return true
  } else {
    dispatch(setStudyHome({}))
    return false
  }
}

// 스크린 닉네임 추가
export const postPwReqAction = param => async dispatch => {
  const result = await ExploringApis.postPwReq(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
