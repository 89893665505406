import axios from "axios"
import { navigate } from "gatsby"
import { configureStore } from "../store"
import { setLoadingPopup, setServerErrorPopup } from "../store/modules/config"
import { setIsLogin } from "../store/modules/sign"
import { debug, isApp, localStorgeGet } from "../utils/util"
import { useDispatch } from "react-redux"

const apiFunction = method => {
  return async (url, { body = {}, header = {}, token = false } = {}) => {
    const headers = {
      Accept: "application/json",
      ...header,
    }

    if (token) {
      if (window.ReactNativeWebView) {
        headers.Authorization = `Bearer ${localStorgeGet(process.env.TOKEN_ID)}`
      } else {
        headers.Authorization = `Bearer ${sessionStorage.getItem(process.env.TOKEN_ID)}`
      }
    }

    try {
      if (method == "GET") {
        // configureStore.dispatch(setLoadingPopup(true))
      }
      const result = await axios({
        method: method,
        url: process.env.API_URL + url,
        data: body,
        headers: headers,
      })
      // debug(result)
      // console.log(result)
      if (result.data.code == 1 && result.data.message != undefined) {
        configureStore.dispatch(setServerErrorPopup({ open: true, text: result.data.message }))
      }
      return result
    } catch (err) {
      if (err.response) {
        // debug(err.response)
        console.log(err.response)
        if (err.response.status === 400) {
          configureStore.dispatch(setServerErrorPopup({ open: true, text: err.response.data.message }))
          return err.response
        } else if (err.response.status === 401) {
          if (window.ReactNativeWebView) {
            localStorage.removeItem(process.env.TOKEN_ID)
          } else {
            sessionStorage.removeItem(process.env.TOKEN_ID)
          }

          localStorage.removeItem("loginUserSeq")
          localStorage.removeItem("naverType")
          localStorage.removeItem("googleType")
          localStorage.removeItem("appleType")
          localStorage.removeItem("paySeq")
          localStorage.removeItem("responseNaver")
          configureStore.dispatch(setIsLogin(false))
          return err.response
        } else if (err.response.status === 403) {
          configureStore.dispatch(setIsLogin(false))
          if (window.ReactNativeWebView) {
            localStorage.removeItem(process.env.TOKEN_ID)
          } else {
            sessionStorage.removeItem(process.env.TOKEN_ID)
          }

          localStorage.removeItem("loginUserSeq")
          localStorage.removeItem("naverType")
          localStorage.removeItem("googleType")
          localStorage.removeItem("appleType")
          localStorage.removeItem("paySeq")
          localStorage.removeItem("responseNaver")
          navigate("/exploring")
          return err.response
        } else if (err.response.status === 409) {
          // 로그인 실패
          return err.response
        } else if (err.response.status === 412) {
          if (isApp) window.callNative(JSON.stringify({ Type: "CookieRemove", FunctionName: "" }))
          configureStore.dispatch(setIsLogin(false))
          if (window.ReactNativeWebView) {
            localStorage.removeItem(process.env.TOKEN_ID)
          } else {
            sessionStorage.removeItem(process.env.TOKEN_ID)
          }

          localStorage.removeItem("loginUserSeq")
          localStorage.removeItem("naverType")
          localStorage.removeItem("googleType")
          localStorage.removeItem("appleType")
          localStorage.removeItem("paySeq")
          localStorage.removeItem("responseNaver")
          navigate("/exploring")

          return err.response
        } else if (err.response.status === 500) {
          configureStore.dispatch(setServerErrorPopup({ open: true, text: err.response.data.message }))
          return err.response
        }
      }
    } finally {
      // configureStore.dispatch(setLoadingPopup(false))
    }
  }
}

const api = {
  post: apiFunction("POST"),
  get: apiFunction("GET"),
  delete: apiFunction("DELETE"),
  patch: apiFunction("PATCH"),
  put: apiFunction("PUT"),
}

export default api
