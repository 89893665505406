import { navigate } from "gatsby-link"
import { createAction, handleActions } from "redux-actions"
import PaymentsApis from "../../apis/PaymentsApis"

const PAYMENTS_ORDER_ID = "payments/PAYMENTS_ORDER_ID"

export const setPaymentsOrderId = createAction(PAYMENTS_ORDER_ID)

const initState = {
  orderId: "",
}

export default handleActions(
  {
    [PAYMENTS_ORDER_ID]: (state, action) => ({
      ...state,
      orderId: action.payload,
    }),
  },
  initState
)
// 결제

// 결제 ID 생성
export const postPaymentsOrderAction = param => async dispatch => {
  const result = await PaymentsApis.postPaymentsOrder(param)
  if (result.code == 0 || result.code == 1) {
    return result.data
  } else {
    return false
  }
}
// 결제 검증
export const postPaymentsVerificationAction = param => async dispatch => {
  const result = await PaymentsApis.postPaymentsVerification(param)
  if (result.code == 0 || result.code == 1) {
    return result.data
  } else {
    return false
  }
}
// 결제 성공
export const postPaymentsSuccessAction = param => async dispatch => {
  const result = await PaymentsApis.postPaymentsSuccess(param)
  if (result.code == 0 || result.code == 1) {
    return result.data
  } else {
    return false
  }
}
// 결제 실패
export const postPaymentsFailAction = param => async dispatch => {
  const result = await PaymentsApis.postPaymentsFail(param)
  if (result.code == 0 || result.code == 1) {
    return result.data
  } else {
    return false
  }
}

// 관리자 유형관리 수정
// export const putAdminsTypeAction = param => async dispatch => {
//   const result = await MasterApis.putAdminsType(param)
//   if (result.code == 0||result.code==1) {
//     return true
//   } else return false
// }

//어드민 삭제
// export const delAdminsAction = param => async dispatch => {
//   const result = await MasterApis.delAdmins(param)
//   if (result.code == 0||result.code==1) {
//     return true
//   } else return false
// }

// //권한 변경 요청
// export const postAdminsProfileAuthAction = param => async dispatch => {
//   const result = await SignApis.postAdminsProfileAuth(param)
//   if (result.code == 0||result.code==1) {
//     return true
//   } else return false
// }
