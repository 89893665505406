import api from "../utils/api"

//유저보기
const postUserProfile = async data => {
  const result = await api.get(`/user/profile?userSeq=${data.managerUserSeq}&userType=${data.managerUserType}`, {
    body: data,
    token: true,
  })
  if (result) {
    return result.data
  } else return false
}

//유저 참여 스터디보기
const getUserStudiesParticipation = async data => {
  const result = await api.get(`/user/studies/participation?userSeq=${data.managerUserSeq}&userType=${data.managerUserType}`, {
    body: data,
    token: true,
  })
  if (result) {
    return result.data
  } else return false
}

//친구목록
const getUserStudyBuddies = async data => {
  const result = await api.get(`/user/study-buddies?page=${data.page}&count=${data.count}`, {
    body: data,
    token: true,
  })
  if (result) {
    return result.data
  } else return false
}

//친구 추가
const postUserStudyBuddy = async data => {
  const result = await api.post(`/user/study-buddies`, { body: data, token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//친구 삭제
const delUserStudyBuddy = async data => {
  const result = await api.delete(`/user/study-buddies`, { body: data, token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//공부장소 추가
const postUserStudyLocation = async data => {
  const result = await api.post(`/user/study-locations`, { body: data, token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}
//공부장소 삭제
const delUserStudyLocation = async data => {
  const result = await api.delete(`/user/study-locations`, { body: data, token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}
//공부장소 변경
const putUserStudyLocation = async data => {
  const result = await api.put(`/user/study-locations`, { body: data, token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//스크린 닉네임 추가
const postUserNickname = async data => {
  const result = await api.post(`/user/nicknames`, { body: data, token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//스크린 닉네임 중복체크
const postUserNicknameCheck = async data => {
  const result = await api.post(`/duplicate/nickname/check`, { body: data, token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 학습자 그룸 리스트
const getUserStudentGroups = async data => {
  const result = await api.get(`/student-groups`, { token: true })
  return result.data
}

// 학습자 그룹 추가
const postUserStudentGroup = async data => {
  const result = await api.post(`/user/student-groups`, { body: data, token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 이메일 추가 추가
const postUserEmails = async data => {
  const result = await api.post(`/user/emails`, { body: data, token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//내 스크린 닉네임 수정
const putUserNikckname = async data => {
  const result = await api.put(`/user/nicknames`, { token: true, body: data })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//내 프로필 이미지 수정
const putUserProfileImage = async data => {
  const result = await api.put(`/user/profile/image`, { token: true, body: data })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//파일 업로드 단일
const postUploadFile = async data => {
  const result = await api.post(`/upload/file`, { body: data, token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//내 설정 정보
const getUserProfileMySetting = async data => {
  const result = await api.get(`/user/profile/my/settings`, { token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//메세지 보내기
const postMessageSend = async data => {
  const result = await api.post(`/messages`, { body: data, token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//메세지 삭제
const delMessages = async data => {
  const result = await api.delete(`/messages`, { body: data, token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//메세지 이미지 목록
const getMessgaeImages = async data => {
  const result = await api.get(`/message/images`, { token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}

// 받은메세지list
const getMessageSend = async data => {
  const result = await api.get(`/messages/send?page=${data.page}&count=${data.count}`, { token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//보낸메세지list
const getMessageReceive = async data => {
  const result = await api.get(`/messages/receive?page=${data.page}&count=${data.count}`, { token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//메세지 상세
const getMessageDetail = async data => {
  const result = await api.get(`/messages?messageSeq=${data.messageSeq}&type=${data.type}`, { token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//이메일 수정 인증 이메일 보내기
const postAuthEmail = async data => {
  const result = await api.post(`/auth/emails`, { token: true, body: data })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//이메일 수정 인증
const putAuthEmail = async data => {
  const result = await api.put(`/auth/emails/${data.authNumber}/${data.authString}`, { token: true })
  // const result = await api.put(`/auth/emails?authNumber=${data.authNumber}&authString=${data.authString}`, { token: true, body: data })

  if (result) {
    return result.data
  } else {
    return false
  }
}

//이메일 수정
const putUserProFileEmail = async data => {
  const result = await api.put(`/user/profile/email`, { token: true, body: data })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//핸드폰 번호 수정
const putUserProfliePhone = async data => {
  const result = await api.put(`/user/profile/phone`, { token: true, body: data })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//내 스터디 콘텐츠 공개 설정
const putUserSettingsContent = async data => {
  const result = await api.put(`/user/settings/content`, { token: true, body: data })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//내 컴퓨터 초기 장치 설정
const putUserSettingGear = async data => {
  const result = await api.put(`/user/settings/gear`, { token: true, body: data })
  if (result) {
    return result.data
  } else {
    return false
  }
}
//친구 추천 설정
const putUserSettingRecommendation = async data => {
  const result = await api.put(`/user/settings/recommendation`, { token: true, body: data })
  if (result) {
    return result.data
  } else {
    return false
  }
}
//내 알림 설정
const putUserSettingAlarm = async data => {
  const result = await api.put(`/user/settings/alarm`, { token: true, body: data })
  if (result) {
    return result.data
  } else {
    return false
  }
}
//내 약관 설정
const putUserSettingTerm = async data => {
  const result = await api.put(`/user/settings/term`, { token: true, body: data })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//회원 탈퇴
const putWithdraw = async data => {
  const result = await api.put(`/withdraw`, { token: true, body: data })
  if (result) {
    return result.data
  } else {
    return false
  }
}

//탈퇴사유 리스트
const getWithdrawReasons = async data => {
  const result = await api.get(`/withdraw-reasons`, { token: true })
  if (result) {
    return result.data
  } else {
    return false
  }
}
///subscriptions 컨설던트 스터디 투두 신청
const postSubscriptions = async data => {
  const result = await api.post(`/subscriptions`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
///subscriptions 컨설던트 상담신청 신청
const postConsultings = async data => {
  const result = await api.post(`/consultings`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}

export default {
  postUserStudyLocation,
  postUserNickname,
  getUserStudentGroups,
  postUserStudentGroup,
  postUserProfile,
  getUserStudyBuddies,
  postUserStudyBuddy,
  delUserStudyBuddy,
  postMessageSend,
  getMessgaeImages,
  delMessages,
  getUserStudiesParticipation,
  putUserNikckname,
  putUserProfileImage,
  postUploadFile,
  getUserProfileMySetting,
  getMessageSend,
  getMessageReceive,
  getMessageDetail,
  delUserStudyLocation,
  putUserStudyLocation,
  postAuthEmail,
  putAuthEmail,
  putUserProFileEmail,
  putUserProfliePhone,
  putUserSettingsContent,
  putUserSettingGear,
  putWithdraw,
  getWithdrawReasons,
  postUserEmails,
  putUserSettingRecommendation,
  putUserSettingAlarm,
  putUserSettingTerm,
  postSubscriptions,
  postConsultings,
  postUserNicknameCheck,
}
