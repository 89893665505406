// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-auth-emails-jsx": () => import("./../../../src/pages/authEmails.jsx" /* webpackChunkName: "component---src-pages-auth-emails-jsx" */),
  "component---src-pages-auth-login-apple-jsx": () => import("./../../../src/pages/authLoginApple.jsx" /* webpackChunkName: "component---src-pages-auth-login-apple-jsx" */),
  "component---src-pages-auth-login-google-jsx": () => import("./../../../src/pages/authLoginGoogle.jsx" /* webpackChunkName: "component---src-pages-auth-login-google-jsx" */),
  "component---src-pages-auth-login-jsx": () => import("./../../../src/pages/authLogin.jsx" /* webpackChunkName: "component---src-pages-auth-login-jsx" */),
  "component---src-pages-auth-order-jsx": () => import("./../../../src/pages/authOrder.jsx" /* webpackChunkName: "component---src-pages-auth-order-jsx" */),
  "component---src-pages-auth-sign-emails-jsx": () => import("./../../../src/pages/authSignEmails.jsx" /* webpackChunkName: "component---src-pages-auth-sign-emails-jsx" */),
  "component---src-pages-exploring-jsx": () => import("./../../../src/pages/exploring.jsx" /* webpackChunkName: "component---src-pages-exploring-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-my-analysis-jsx": () => import("./../../../src/pages/my/analysis.jsx" /* webpackChunkName: "component---src-pages-my-analysis-jsx" */),
  "component---src-pages-my-buddy-jsx": () => import("./../../../src/pages/my/buddy.jsx" /* webpackChunkName: "component---src-pages-my-buddy-jsx" */),
  "component---src-pages-my-contents-jsx": () => import("./../../../src/pages/my/contents.jsx" /* webpackChunkName: "component---src-pages-my-contents-jsx" */),
  "component---src-pages-my-dashboard-jsx": () => import("./../../../src/pages/my/dashboard.jsx" /* webpackChunkName: "component---src-pages-my-dashboard-jsx" */),
  "component---src-pages-my-message-jsx": () => import("./../../../src/pages/my/message.jsx" /* webpackChunkName: "component---src-pages-my-message-jsx" */),
  "component---src-pages-my-notice-jsx": () => import("./../../../src/pages/my/notice.jsx" /* webpackChunkName: "component---src-pages-my-notice-jsx" */),
  "component---src-pages-my-point-jsx": () => import("./../../../src/pages/my/point.jsx" /* webpackChunkName: "component---src-pages-my-point-jsx" */),
  "component---src-pages-my-purchase-jsx": () => import("./../../../src/pages/my/purchase.jsx" /* webpackChunkName: "component---src-pages-my-purchase-jsx" */),
  "component---src-pages-my-schedule-jsx": () => import("./../../../src/pages/my/schedule.jsx" /* webpackChunkName: "component---src-pages-my-schedule-jsx" */),
  "component---src-pages-my-setting-jsx": () => import("./../../../src/pages/my/setting.jsx" /* webpackChunkName: "component---src-pages-my-setting-jsx" */)
}

