import { createAction, handleActions } from "redux-actions"
import IntroApis from "../../apis/IntroApis"
import { setIntroPopup } from "./popup"
/* action type */
const MAIN_INTRO = "/intro/MAIN_INTRO"
const MAIN_GATEWAY = "/intro/MAIN_GATEWAY"
const MAIN_GATEWAY_MO = "/intro/MAIN_GATEWAY_MO"
const PROMOTION_ROLLING_TOP_PC = "/intro/PROMOTION_ROLLING_TOP_PC"
const PROMOTION_ROLLING_TOP_MO = "/intro/PROMOTION_ROLLING_TOP_MO"
const PROMOTION_ROLLING_BOTTOM_PC = "/intro/PROMOTION_ROLLING_BOTTOM_PC"
const PROMOTION_ROLLING_BOTTOM_MO = "/intro/PROMOTION_ROLLING_BOTTOM_MO"
const PROMOTION_HORIZONTAL_TOP_PC = "/intro/PROMOTION_HORIZONTAL_TOP_PC"
const PROMOTION_HORIZONTAL_TOP_MO = "/intro/PROMOTION_HORIZONTAL_TOP_MO"
const PROMOTION_HORIZONTAL_BOTTOM_PC = "/intro/PROMOTION_HORIZONTAL_BOTTOM_PC"
const PROMOTION_HORIZONTAL_BOTTOM_MO = "/intro/PROMOTION_HORIZONTAL_BOTTOM_MO"
const PROMOTION_CARD_HOLD_PC = "/intro/PROMOTION_CARD_HOLD_PC"
const PROMOTION_CARD_HOLD_MO = "/intro/PROMOTION_CARD_HOLD_MO"

/* action function */
export const setMainIntro = createAction(MAIN_INTRO)
export const setMainGateway = createAction(MAIN_GATEWAY)
export const setMainGatewayMo = createAction(MAIN_GATEWAY_MO)

export const setPromotionRoolingTopPc = createAction(PROMOTION_ROLLING_TOP_PC)
export const setPromotionRoolingTopMo = createAction(PROMOTION_ROLLING_TOP_MO)
export const setPromotionRoolingBottomPc = createAction(PROMOTION_ROLLING_BOTTOM_PC)
export const setPromotionRoolingBottomMo = createAction(PROMOTION_ROLLING_BOTTOM_MO)
export const setPromotionHorizontalTopPc = createAction(PROMOTION_HORIZONTAL_TOP_PC)
export const setPromotionHorizontalTopMo = createAction(PROMOTION_HORIZONTAL_TOP_MO)
export const setPromotionHorizontalBottomPc = createAction(PROMOTION_HORIZONTAL_BOTTOM_PC)
export const setPromotionHorizontalBottomMo = createAction(PROMOTION_HORIZONTAL_BOTTOM_MO)
export const setPromotionCardHoldPc = createAction(PROMOTION_CARD_HOLD_PC)
export const setPromotionCardHoldMo = createAction(PROMOTION_CARD_HOLD_MO)

const initState = {
  gatewayContents: [],
  gatewayContentsMo: [],
  introContents: {},
  rollingTopPc: [],
  rollingTopMo: [],
  rollingBottomPc: [],
  rollingBottomMo: [],
  horizonTopPc: [],
  horizonTopMo: [],
  horizonBottomPc: [],
  horizonBottomMo: [],
  cardHoldPc: [],
  cardHoldMo: [],
}

export default handleActions(
  {
    [MAIN_INTRO]: (state, action) => ({
      ...state,
      introContents: action.payload,
    }),
    [MAIN_GATEWAY]: (state, action) => ({
      ...state,
      gatewayContents: action.payload,
    }),
    [MAIN_GATEWAY_MO]: (state, action) => ({
      ...state,
      gatewayContentsMo: action.payload,
    }),
    [PROMOTION_ROLLING_TOP_PC]: (state, action) => ({
      ...state,
      rollingTopPc: action.payload,
    }),
    [PROMOTION_ROLLING_TOP_MO]: (state, action) => ({
      ...state,
      rollingTopMo: action.payload,
    }),
    [PROMOTION_ROLLING_BOTTOM_PC]: (state, action) => ({
      ...state,
      rollingBottomPc: action.payload,
    }),
    [PROMOTION_ROLLING_BOTTOM_MO]: (state, action) => ({
      ...state,
      rollingBottomMo: action.payload,
    }),
    [PROMOTION_HORIZONTAL_TOP_PC]: (state, action) => ({
      ...state,
      horizonTopPc: action.payload,
    }),
    [PROMOTION_HORIZONTAL_TOP_MO]: (state, action) => ({
      ...state,
      horizonTopMo: action.payload,
    }),
    [PROMOTION_HORIZONTAL_BOTTOM_PC]: (state, action) => ({
      ...state,
      horizonBottomPc: action.payload,
    }),
    [PROMOTION_HORIZONTAL_BOTTOM_MO]: (state, action) => ({
      ...state,
      horizonBottomMo: action.payload,
    }),
    [PROMOTION_CARD_HOLD_PC]: (state, action) => ({
      ...state,
      cardHoldPc: action.payload,
    }),
    [PROMOTION_CARD_HOLD_MO]: (state, action) => ({
      ...state,
      cardHoldMo: action.payload,
    }),
  },
  initState
)
/* action api function */

// 인트로
export const getIntroContentsAction = param => async dispatch => {
  const result = await IntroApis.getIntroContents(param)
  if (result.code == 0) {
    dispatch(setMainIntro(result.data.introContents))
    return true
  } else {
    dispatch(setIntroPopup(false))
    dispatch(setMainIntro({}))
    return false
  }
}
//게이트웨이
export const getGatewayContentsAction = param => async dispatch => {
  const result = await IntroApis.getGatewayContents(param)
  if (result.code == 0) {
    dispatch(setMainGateway(result.data.pc))
    dispatch(setMainGatewayMo(result.data.mobile))
    return true
  } else {
    dispatch(setMainGateway([]))
    dispatch(setMainGatewayMo([]))
    return false
  }
}
//프로모션 배너 조회수 +1
export const putContentsPromotionViewAction = param => async dispatch => {
  const result = await IntroApis.putContentsPromotionView(param)
  if (result.code == 0) {
    return true
  } else {
    return false
  }
}
//프로모션
export const getPromotionContentsAction = param => async dispatch => {
  const result = await IntroApis.getPromotionContents(param)
  if (result.code == 0) {
    dispatch(setPromotionRoolingTopPc(result.data.pc.ROLLING_TOP))
    dispatch(setPromotionRoolingBottomPc(result.data.pc.ROLLING_BOTTOM))
    dispatch(setPromotionHorizontalTopPc(result.data.pc.HORIZONTAL_TOP))
    dispatch(setPromotionHorizontalBottomPc(result.data.pc.HORIZONTAL_BOTTOM))
    dispatch(setPromotionCardHoldPc(result.data.pc.CARD_HOLD))
    dispatch(setPromotionRoolingTopMo(result.data.mobile.ROLLING_TOP))
    dispatch(setPromotionRoolingBottomMo(result.data.mobile.ROLLING_BOTTOM))
    dispatch(setPromotionHorizontalTopMo(result.data.mobile.HORIZONTAL_TOP))
    dispatch(setPromotionHorizontalBottomMo(result.data.mobile.HORIZONTAL_BOTTOM))
    dispatch(setPromotionCardHoldMo(result.data.mobile.CARD_HOLD))

    return true
  } else {
    dispatch(setPromotionRoolingTopPc([]))
    dispatch(setPromotionRoolingBottomPc([]))
    dispatch(setPromotionHorizontalTopPc([]))
    dispatch(setPromotionHorizontalBottomPc([]))
    dispatch(setPromotionCardHoldPc([]))
    dispatch(setPromotionRoolingTopMo([]))
    dispatch(setPromotionRoolingBottomMo([]))
    dispatch(setPromotionHorizontalTopMo([]))
    dispatch(setPromotionHorizontalBottomMo([]))
    dispatch(setPromotionCardHoldMo([]))

    return false
  }
}

//스크린 닉네임 추가
// export const postUserNicknameAction = param => async dispatch => {
//   const result = await UserApis.postUserNickname(param)
//   if (result.code == 0) {
//     return true
//   } else return false
// }
