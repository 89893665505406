import api from "../utils/api"

// 결제

// 결제 ID 생성
const postPaymentsOrder = async data => {
  const result = await api.post(`/payments/order`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//결제 검증
const postPaymentsVerification = async data => {
  const result = await api.post(`/payments/verification`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//결제 성공
const postPaymentsSuccess = async data => {
  const result = await api.post(`/payments/success`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//결제 실패
const postPaymentsFail = async data => {
  const result = await api.post(`/payments/fail`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}

export default {
  postPaymentsOrder,
  postPaymentsVerification,
  postPaymentsSuccess,
  postPaymentsFail,
}
