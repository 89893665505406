import api from "../utils/api"

//
//내정보보기
const getMyProfile = async data => {
  const result = await api.get(`/user/profile/my`, {
    body: data,
    token: true,
  })
  if (result) {
    return result.data
  } else return false
}

//get 마이 렛미업
const getMyLetmeupStatistics = async data => {
  const result = await api.get(`/statistics/my?period=${data.period}`, { token: true })
  if (result) {
    return result.data
  } else return false
}

//키워드 랭크
const getMyLetmeupStatisticsKeyword = async data => {
  const result = await api.get(`/ranking/study-keyword`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//누적공부시간 랭크
const getMyLetmeupStatisticsStudyTime = async data => {
  const result = await api.get(`/ranking/study-time`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//베스트 스터디 랭크
const getMyLetmeupStatisticsBestStudy = async data => {
  const result = await api.get(`/ranking/study-best`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//get 내 todo list
const getTodoTasks = async data => {
  const result = await api.get(`/todos?todoDate=${data}`, { token: true })
  if (result) {
    return result.data
  } else return false
}

//todo add
const postTodoTaskAdd = async data => {
  const result = await api.post(`/todos`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//todo edit
const putTodoTaskEdit = async data => {
  const result = await api.put(`/todos`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//todo edit
const delTodoTask = async data => {
  const result = await api.delete(`/todos`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//get todo list best
const getTodosBest = async data => {
  const result = await api.get(`/todos/today-best`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//get today todo list 신청
const getTodayTodos = async data => {
  const result = await api.get(`/todos/alarm`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//get today todo list 가져오기
const postTodosByAlarm = async data => {
  const result = await api.post(`/todos/by-alarm`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//get todo list all
const getTodosAll = async data => {
  const result = await api.get(`/todos/all?keyword=${data.keyword}&page=${data.page}&count=${data.count}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//get todo all keyword list
const getKeywordRecently = async data => {
  const result = await api.get(`/keywords/recently`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//todo like
const postTodoFavorite = async data => {
  const result = await api.put(`/todos/today-best/favorite`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//todo bookmark
const postTodoSave = async data => {
  const result = await api.put(`/todos/today-best/bookmark`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//get 내 공부 리스트
const getMyParticipatingStudies = async data => {
  const result = await api.get(`/user/studies/participation`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//get 누적 공부시간 내순위
const getMyStatisticsStudyimeMe = async data => {
  const result = await api.get(`/statistics/my/study-time`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//get ai 민감도 분석
const getMyAnalysisAi = async data => {
  const result = await api.get(`/analysis/ai`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//get 뇌파 목록 조회
const getBrainMonitorings = async data => {
  const result = await api.get(`/brain/monitorings`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//get 학습자 규칙 분석
const getMyAnalysisRule = async data => {
  const result = await api.get(`/analysis/rule`, { token: true })
  if (result) {
    return result.data
  } else return false
}

//get 마이렛미업 포인트
const getPointMy = async data => {
  const result = await api.get(`/points/my`, { token: true })
  if (result) {
    return result.data
  } else return false
}

//get 마이렛미업 포인트 내역
const getPointLogs = async data => {
  const result = await api.get(`/points?periodType=${data.periodType}&searchType=${data.searchType}&page=${data.page}&count=20`, { token: true })
  if (result) {
    return result.data
  } else return false
}

//get 결제내역
const getOrderLog = async data => {
  const result = await api.get(`/orders?periodType=${data.periodType}&no=${data.no}&count=20`, { token: true })
  if (result) {
    return result.data
  } else return false
}

//get 공지사항
const getNotices = async data => {
  const result = await api.get(`/notices?type=${data.type}&page=${data.page}&count=${data.count}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//get 공지사항
const getNotice = async data => {
  const result = await api.get(`/notices/${data.noticeSeq}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//파트너 프로필 get
const getPartnersProfile = async data => {
  const result = await api.get(`/partners/profile/${data.partnerSeq}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//받은초대문자
const getInvitationsReceive = async data => {
  const result = await api.get(`/invitations/receive`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//보낸초대문자
const getInvitationsSend = async data => {
  const result = await api.get(`/invitations/send`, { token: true })
  if (result) {
    return result.data
  } else return false
}

//초대문자 삭제
const delInvitations = async data => {
  const result = await api.delete(`/invitations`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//컨텐츠 브랜드 목록
const getContentsBrandName = async data => {
  const result = await api.get(`/contents/brandName`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//추천 컨텐츠 목록
const getContentsRecommendation = async data => {
  const result = await api.get(`/contents/recommendation?contentSeq=${data.contentSeq}&brandName=${data.brandName}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//내 컨텐츠 목록
const getContentsMy = async data => {
  const result = await api.get(`/contents/my?contentReadSeq=${data.contentReadSeq}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//내 컨텐츠 추가
const postContentsMy = async data => {
  const result = await api.post(`/contents/my`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//내 컨텐츠 삭제
const delContentsMy = async data => {
  const result = await api.delete(`/contents/my`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}

export default {
  getMyLetmeupStatistics,
  getMyLetmeupStatisticsKeyword,
  getMyLetmeupStatisticsStudyTime,
  getMyLetmeupStatisticsBestStudy,
  getTodoTasks,
  postTodoTaskAdd,
  putTodoTaskEdit,
  delTodoTask,
  getTodosBest,
  getMyParticipatingStudies,
  getMyProfile,
  getMyStatisticsStudyimeMe,
  getMyAnalysisAi,
  getMyAnalysisRule,
  getBrainMonitorings,
  getOrderLog,
  getPointMy,
  getPointLogs,
  postTodoFavorite,
  postTodoSave,
  getTodosAll,
  getKeywordRecently,

  getNotices,
  getNotice,
  getPartnersProfile,
  getTodayTodos,
  postTodosByAlarm,

  getInvitationsReceive,
  getInvitationsSend,
  delInvitations,

  getContentsBrandName,
  getContentsRecommendation,
  getContentsMy,
  postContentsMy,
  delContentsMy,
}
