import { createAction, handleActions } from "redux-actions"

const SET_JITSI_INFO = "jitsiInfo/set-jitsi-info"
const SET_ATTENTION = "jitsiInfo/brainwave-attention"
const SET_MEDITATION = "jitsiInfo/brainwave-meditation"

export const setJitsiInfo = createAction(SET_JITSI_INFO)
export const setAttention = createAction(SET_ATTENTION)
export const setMeditation = createAction(SET_MEDITATION)

const initState = {
  name: "",
  seq: "",
  roomInfo: {},
  attention: [],
  meditation: [],
  aiSensitiveRules: {
    cameraOut: {},
    movement: {},
    seatOut: {},
    sleep: {},
    studyConcentration: {},
  },
}

export default handleActions(
  {
    [SET_JITSI_INFO]: (state, action) => ({
      ...state,
      name: action.payload.name,
      seq: action.payload.seq,
      roomInfo: action.payload.roomInfo,
      aiSensitiveRules: action.payload.aiSensitiveRules,
    }),
    [SET_ATTENTION]: (state, action) => ({
      ...state,
      attention: action.payload,
    }),
    [SET_MEDITATION]: (state, action) => ({
      ...state,
      meditation: action.payload,
    }),
  },
  initState
)
