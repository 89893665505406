import api from "../utils/api"

//스터디 만들기
const postStudyMakeRoom = async data => {
  const result = await api.post(`/study/room`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//스터디 추천프로그램
const getRecommendProgram = async data => {
  const result = await api.get(`/study/recommend-program`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//스터디 시작하기
const postStudyRoomStart = async data => {
  const result = await api.post(`/study/room/start`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//스터디 pw check
const postStudyPasswordCheck = async data => {
  const result = await api.post(`/study/password/check`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}

//검색공개 수정
const putStudyRoomSettingSearch = async data => {
  const result = await api.put(`/study/room/setting/search`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}

//비밀번호 수정
const putStudyRoomSettingPassword = async data => {
  const result = await api.put(`/study/room/setting/password`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//초기장치 수정
const putStudyRoomSettingDevice = async data => {
  const result = await api.put(`/study/room/setting/device`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//인원수 수정
const putStudyRoomPersonnel = async data => {
  const result = await api.put(`/study/room/setting/participation-personnel`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//상벌점 수정
const putStudyRoomSettingScore = async data => {
  const result = await api.put(`/study/room/setting/score`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//Ai 수정
const putStudyRoomSettingAI = async data => {
  const result = await api.put(`/study/room/setting/ai-sensitive`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//스터디 날짜 수정
const putStudyRoomSettingDate = async data => {
  const result = await api.put(`/study/room/setting/study-date`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//스터디 시간 수정
const putStudyRoomSettingLoop = async data => {
  const result = await api.put(`/study/room/setting/schedule`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}
//스터디 설정 조회
const getStudyRoomSetting = async data => {
  const result = await api.get(`/study/room/settings?studySeq=${data}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//스터디  조회
const getStudyRoomInfo = async data => {
  const result = await api.get(`/study/room?studySeq=${data}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//스터디 상벌점 조회
const getStudyRoomLearnAttitude = async data => {
  const result = await api.get(`/study/learn-attitude-rules?studySeq=${data}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
//스터디 상벌점 조회
const getStudyRoomAi = async data => {
  const result = await api.get(`/study/ai-sensitive-rules?studySeq=${data}`, { token: true })
  if (result) {
    return result.data
  } else return false
}

//
// const getTerm = async data => {
//   const result = await api.get(`/term`, { token: true })
//   return result.data
// }

export default {
  postStudyMakeRoom,
  postStudyRoomStart,
  postStudyPasswordCheck,
  putStudyRoomSettingPassword,
  putStudyRoomSettingDevice,
  getRecommendProgram,
  getStudyRoomSetting,
  getStudyRoomInfo,
  putStudyRoomSettingSearch,
  putStudyRoomPersonnel,
  getStudyRoomLearnAttitude,
  getStudyRoomAi,
  putStudyRoomSettingScore,
  putStudyRoomSettingAI,
  putStudyRoomSettingDate,
  putStudyRoomSettingLoop,

  // getTerm,
}
