import api from "../utils/api"

// 회원가입
const postSignUp = async data => {
  const result = await api.post(`/signup`, { token: false, body: data })
  if (result) {
    return result.data
  } else return false
}
// 타입받기
const postAuthentication = async data => {
  const result = await api.post(`/signin/authentication`, { token: true })
  if (result) {
    return result.data
  } else return false
}

// new 회원가입
const postSignUpV2 = async data => {
  const result = await api.post(`/signup/v2`, { token: false, body: data })
  if (result) {
    return result.data
  } else return false
}

// 로그인
const postSignIn = async data => {
  const result = await api.post(`/login`, { token: false, body: data })
  if (result) {
    return result.data
  } else return false
}

// 로그인 상태 체크
const checkSignStatus = async data => {
  const result = await api.post(`/signin/status`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}

// 로그아웃
const postSignOut = async data => {
  const result = await api.post(`/signout`, { body: data })
  if (result) {
    return result.data
  } else return false
}

// 약관
const getTerm = async data => {
  const result = await api.get(`/terms`)
  if (result) {
    return result.data
  } else return false
}

//인증번호요청
const postAuthNumber = async data => {
  const result = await api.post(`/auth/number`, { body: data, token: true })
  if (result) {
    return result.data
  } else return false
}
//인증번호요청 제휴사
const postPartnerAuthNumber = async data => {
  const result = await api.post(`/partner/auth/number`, { body: data })
  if (result) {
    return result.data
  } else return false
}

// 제휴사 로그인
const putPartnerAuth = async data => {
  const result = await api.put(`/partner/auth/number`, { body: data, token: true })
  if (result) {
    return result.data
  } else return false
}

//인증번호인증 확인
const postAuthNumberCheck = async data => {
  const result = await api.put(`/auth/number`, { body: data })
  if (result) {
    return result.data
  } else return false
}

// 공부장소 리스트
const postGetStudyLocations = async data => {
  const result = await api.post(`/auth/study-locations`, { body: data, token: true })
  if (result) {
    return result.data
  } else return false
}

// 공부장소 리스트 인증
const postStudyLocations = async data => {
  const result = await api.put(`/auth/study-locations`, { body: data, token: true })
  if (result) {
    return result.data
  } else return false
}

///new signup 회원가입용 소셜 or 이메일 인증
const postSignupDatas = async data => {
  const result = await api.post(`/signup/datas`, { body: data })
  if (result) {
    return result.data
  } else return false
}

// 회원가입용 이메일 인증하기
const putSignupDatas = async data => {
  const result = await api.put(`/signup/datas/${data.customerAppSignDataSeq}/${data.authString}`, { body: data })
  if (result) {
    return result.data
  } else return false
}
//비밀번호찾기

const postPasswordFind = async data => {
  const result = await api.post(`/password/find`, { body: data, token: true })
  if (result) {
    return result.data
  } else return false
}
const putPasswordFind = async data => {
  const result = await api.put(`/password/find`, { body: data, token: true })
  if (result) {
    return result.data
  } else return false
}
const putPasswordReset = async data => {
  const result = await api.put(`/password/find/reset`, { body: data, token: true })
  if (result) {
    return result.data
  } else return false
}

export default {
  postSignIn,
  checkSignStatus,
  postSignOut,
  getTerm,
  postAuthNumber,
  postAuthNumberCheck,
  postGetStudyLocations,
  postStudyLocations,
  postSignUp,
  postSignupDatas,
  putSignupDatas,
  postSignUpV2,
  postPartnerAuthNumber,
  putPartnerAuth,
  postPasswordFind,
  putPasswordFind,
  putPasswordReset,
  postAuthentication,
}
