import api from "../utils/api"

//
// const postStudyMakeRoom = async data => {
//   const result = await api.post(`/study/room`, { token: true, body: data })
//   return result.data
// }

//get 스터디 탐색
const getStudyhome = async data => {
  const result = await api.get(`/study/home?region3=${data.region3}&tag=${data.tag}`, { token: true })
  if (result) {
    return result.data
  } else return false
}
const postPwReq = async data => {
  const result = await api.post(`/study/password/request`, { token: true, body: data })
  if (result) {
    return result.data
  } else return false
}

export default {
  getStudyhome,
  postPwReq,
}
