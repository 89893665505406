import api from "../utils/api"

// 게이트웨이 콘텐츠 리스트
const getGatewayContents = async data => {
  const result = await api.get(`/contents/gateway`, { token: true })
  if (result) {
    return result.data
  } else return false
}
// 인트로 콘텐츠 리스트
const getIntroContents = async data => {
  const result = await api.get(`/contents/intro`, { token: true })
  if (result) {
    return result.data
  } else return false
}
// 프로모션 콘텐츠 리스트
const getPromotionContents = async data => {
  const result = await api.get(`/contents/promotion`, { token: false })
  if (result) {
    return result.data
  } else return false
}
//프로모션 배너 조회수 +1
const putContentsPromotionView = async data => {
  const result = await api.put(`/contents/promotion/view`, { body: data, token: false })
  return result.data
}

export default {
  getGatewayContents,
  getIntroContents,
  getPromotionContents,
  putContentsPromotionView,
}
