import moment from "moment"
import { useSelector } from "react-redux"
import { createAction, handleActions } from "redux-actions"
import MyApis from "../../apis/MyApis"
import UserApis from "../../apis/UserApis"
import { configureStore } from "../../store"
/* action type */
const MY_LETMEUP = "/my/MY_LETMEUP"
const MY_PROFILE = "/my/MY_PROFILE"
const MY_LETMEUP_CHART_OPTION = "/my/MY_LETMEUP_CHART_OPTION"
const MY_LETMEUP_KEYWORD_RANK = "/my/MY_LETMEUP_KEYWORD_RANK"
const MY_LETMEUP_STUDY_RANK = "/my/MY_LETMEUP_STUDY_RANK"
const MY_LETMEUP_BEST_STUDY_RANK = "/my/MY_LETMEUP_BEST_STUDY_RANK"
const MY_TODO_LIST = "/my/MY_TODO_LIST"
const MY_TODO_LIST_ALL = "/my/MY_TODO_LIST_ALL"
const MY_TODO_LIST_ALL_TOTAL = "/my/MY_TODO_LIST_ALL_TOTAL"
const MY_TODO_LIST_KEYWORD_LIST = "/my/MY_TODO_LIST_KEYWORD_LIST"
const MY_TODOS_BEST_LIST = "/my/MY_TODOS_BEST_LIST"
const MY_PARTICIPATING_STUDIES_LIST = "/my/MY_PARTICIPATING_STUDIES_LIST"
const MY_PARTICIPATING_STUDIES_LIST_PC = "/my/MY_PARTICIPATING_STUDIES_LIST_PC"
const MY_STATISTICS_STUDYTIME_ME = "/my/MY_STATISTICS_STUDYTIME_ME"
const MY_ANALYSIS_AI = "/my/MY_ANALYSIS_AI"
const MY_ANALYSIS_RULE = "/my/MY_ANALYSIS_RULE"
const MY_ANALYSIS_BRAIN = "/my/MY_ANALYSIS_BRAIN"
const MY_POINT = "/my/MY_POINT"
const MY_POINT_LOG = "/my/MY_POINT_LOG"
const MY_POINT_LOG_TOTAL = "/my/MY_POINT_LOG_TOTAL"
const MY_ORDER_LOG = "/my/MY_ORDER_LOG"
const MY_ORDER_LOG_NO = "/my/MY_ORDER_LOG_NO"
const MY_ORDER_LOG_INFO = "/my/MY_ORDER_LOG_INFO"
const MY_ORDER_LOG_TOTAL = "/my/MY_ORDER_LOG_TOTAL"
const MY_NOTICES = "/my/MY_NOTICES"
const MY_NOTICES_TOTAL = "/my/MY_NOTICES_TOTAL"
const MY_NOTICE = "/my/MY_NOTICE"
const MY_TODAY_TODOS = "/my/MY_TODAY_TODO_TIMES"
const MY_TODAY_TODO_TIME = "/my/MY_TODAY_TODO_TIME"

const MY_INVITATIONS = "/my/MY_INVITATIONS"

const MY_CONTENTS_BRAND = "/my/MY_CONTENTS_BRAND"

const MY_CONTENTS_RECOMMENDATION = "/my/MY_CONTENTS_RECOMMENDATION"
const MY_CONTENTS_RECOMMENDATION_TOTAL = "/my/MY_CONTENTS_RECOMMENDATION_TOTAL"
const MY_CONTENTS = "/my/MY_CONTENTS"
const MY_CONTENTS_TOTAL = "/my/MY_CONTENTS_TOTAL"

const TARGET_USER_PROFLIE = "/my/TARGET_USER_PROFLIE"

const CONSULTANT_PROFILE = "my/CONSULTANT_PROFILE"

/* action function */
export const setMyProfile = createAction(MY_PROFILE)
export const setMyLetmeup = createAction(MY_LETMEUP)
export const setMyLetmeupChartOption = createAction(MY_LETMEUP_CHART_OPTION)
export const setMyLetmeupKeywordRank = createAction(MY_LETMEUP_KEYWORD_RANK)
export const setMyLetmeupStudyRank = createAction(MY_LETMEUP_STUDY_RANK)
export const setMyLetmeupBestStudyRank = createAction(MY_LETMEUP_BEST_STUDY_RANK)
export const setMyTodoListAll = createAction(MY_TODO_LIST_ALL)
export const setMyTodoListAllTotal = createAction(MY_TODO_LIST_ALL_TOTAL)
export const setMyTodoKeywordList = createAction(MY_TODO_LIST_KEYWORD_LIST)
export const setMyTodoList = createAction(MY_TODO_LIST)
export const setMyTodosBestList = createAction(MY_TODOS_BEST_LIST)
export const setMyParticipatingStudiesList = createAction(MY_PARTICIPATING_STUDIES_LIST)
export const setMyParticipatingStudiesListPc = createAction(MY_PARTICIPATING_STUDIES_LIST_PC)

export const setMyStatisticsStudytimeMe = createAction(MY_STATISTICS_STUDYTIME_ME)
export const setMyAnalysisAi = createAction(MY_ANALYSIS_AI)
export const setMyAnalysisRule = createAction(MY_ANALYSIS_RULE)
export const setMyAnalysisBrain = createAction(MY_ANALYSIS_BRAIN)

export const setMyPoint = createAction(MY_POINT)
export const setMyPointLog = createAction(MY_POINT_LOG)
export const setMyPointLogTotal = createAction(MY_POINT_LOG_TOTAL)
export const setMyOrderLog = createAction(MY_ORDER_LOG)
export const setMyOrderLogNo = createAction(MY_ORDER_LOG_NO)
export const setMyOrderLogInfo = createAction(MY_ORDER_LOG_INFO)
export const setMyOrderLogTotal = createAction(MY_ORDER_LOG_TOTAL)
export const setMyNotices = createAction(MY_NOTICES)
export const setMyNoticesTotal = createAction(MY_NOTICES_TOTAL)
export const setMyNotice = createAction(MY_NOTICE)
export const setMyTodayTodoTime = createAction(MY_TODAY_TODO_TIME)
export const setMyTodayTodos = createAction(MY_TODAY_TODOS)
export const setMyInvitations = createAction(MY_INVITATIONS)

export const setTargetUserProfile = createAction(TARGET_USER_PROFLIE)
export const setConsultantProfile = createAction(CONSULTANT_PROFILE)

export const setMyContentsBrand = createAction(MY_CONTENTS_BRAND)
export const setMyContentsRecommendation = createAction(MY_CONTENTS_RECOMMENDATION)
export const setMyContentsRecommendationTotal = createAction(MY_CONTENTS_RECOMMENDATION_TOTAL)
export const setMyContents = createAction(MY_CONTENTS)
export const setMyContentstotal = createAction(MY_CONTENTS_TOTAL)

const initState = {
  myLetmeup: {},
  myProfile: {},
  keywordRank: [],
  studyRank: [],
  bestStudyRank: [],
  todoTasksList: [],
  todoBestList: { web: [], mo: [] },
  myStudiesList: [],
  myStudiesListPc: [],
  myStudyTime: {},
  myAi: {},
  myRule: {},
  myBrain: {},
  myPoint: {},
  myPointLog: [],
  myPointLogTotal: 0,
  myOrderLog: [],
  myOrderLogNo: "",
  myOrderLogInfo: {},
  myOrderLogTotal: [],
  myTodoListAll: [],
  myTodoListAllTotal: 0,
  myTodoKeywordList: [],
  myNoticesList: [],
  myNoticesListTotal: 0,
  myNotice: {},
  chatOption: {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        min: 0,
        max: 24,
        ticks: {
          stepSize: 4,
          color: "#000000",
          callback: function (value) {
            return value + "시간"
          },
        },
        grid: {
          drawBorder: false,
        },
      },
    },
  },
  targetUserProfile: {},
  consultantProfile: {},
  todoTimeList: [],
  todayTodoList: [],
  invitationsList: [],
  contentsBarnd: [],
  contents: [],
  contentsTotal: 0,
  contentsRecommendation: [],
  contentsRecommendationTotal: 0,
}

export default handleActions(
  {
    [MY_PROFILE]: (state, action) => ({
      ...state,
      myProfile: action.payload,
    }),
    [MY_LETMEUP]: (state, action) => ({
      ...state,
      myLetmeup: action.payload,
    }),
    [MY_LETMEUP_KEYWORD_RANK]: (state, action) => ({
      ...state,
      keywordRank: action.payload,
    }),
    [MY_LETMEUP_STUDY_RANK]: (state, action) => ({
      ...state,
      studyRank: action.payload,
    }),
    [MY_LETMEUP_CHART_OPTION]: (state, action) => ({
      ...state,
      chatOption: action.payload,
    }),
    [MY_LETMEUP_BEST_STUDY_RANK]: (state, action) => ({
      ...state,
      bestStudyRank: action.payload,
    }),

    [TARGET_USER_PROFLIE]: (state, action) => ({
      ...state,
      targetUserProfile: action.payload,
    }),
    [MY_TODO_LIST]: (state, action) => ({
      ...state,
      todoTasksList: action.payload,
    }),
    [MY_TODOS_BEST_LIST]: (state, action) => ({
      ...state,
      todoBestList: action.payload,
    }),
    [MY_PARTICIPATING_STUDIES_LIST]: (state, action) => ({
      ...state,
      myStudiesList: action.payload,
    }),
    [MY_PARTICIPATING_STUDIES_LIST_PC]: (state, action) => ({
      ...state,
      myStudiesListPc: action.payload,
    }),
    [MY_STATISTICS_STUDYTIME_ME]: (state, action) => ({
      ...state,
      myStudyTime: action.payload,
    }),
    [MY_ANALYSIS_AI]: (state, action) => ({
      ...state,
      myAi: action.payload,
    }),
    [MY_ANALYSIS_RULE]: (state, action) => ({
      ...state,
      myRule: action.payload,
    }),
    [MY_ANALYSIS_BRAIN]: (state, action) => ({
      ...state,
      myBrain: action.payload,
    }),
    [MY_POINT]: (state, action) => ({
      ...state,
      myPoint: action.payload,
    }),
    [MY_POINT_LOG]: (state, action) => ({
      ...state,
      myPointLog: action.payload,
    }),

    [MY_POINT_LOG_TOTAL]: (state, action) => ({
      ...state,
      myPointLogTotal: action.payload,
    }),
    [MY_ORDER_LOG]: (state, action) => ({
      ...state,
      myOrderLog: action.payload,
    }),
    [MY_ORDER_LOG_INFO]: (state, action) => ({
      ...state,
      myOrderLogInfo: action.payload,
    }),
    [MY_ORDER_LOG_TOTAL]: (state, action) => ({
      ...state,
      myOrderLogTotal: action.payload,
    }),
    [MY_ORDER_LOG_NO]: (state, action) => ({
      ...state,
      myOrderLogNo: action.payload,
    }),
    [MY_TODO_LIST_ALL]: (state, action) => ({
      ...state,
      myTodoListAll: action.payload,
    }),
    [MY_TODO_LIST_ALL_TOTAL]: (state, action) => ({
      ...state,
      myTodoListAllTotal: action.payload,
    }),
    [MY_TODO_LIST_KEYWORD_LIST]: (state, action) => ({
      ...state,
      myTodoKeywordList: action.payload,
    }),
    [MY_NOTICES]: (state, action) => ({
      ...state,
      myNoticesList: action.payload,
    }),
    [MY_NOTICES_TOTAL]: (state, action) => ({
      ...state,
      myNoticesListTotal: action.payload,
    }),
    [MY_NOTICE]: (state, action) => ({
      ...state,
      myNotice: action.payload,
    }),
    [CONSULTANT_PROFILE]: (state, action) => ({
      ...state,
      consultantProfile: action.payload,
    }),
    [MY_TODAY_TODO_TIME]: (state, action) => ({
      ...state,
      todoTimeList: action.payload,
    }),
    [MY_TODAY_TODOS]: (state, action) => ({
      ...state,
      todayTodoList: action.payload,
    }),
    [MY_INVITATIONS]: (state, action) => ({
      ...state,
      invitationsList: action.payload,
    }),

    [MY_CONTENTS_BRAND]: (state, action) => ({
      ...state,
      contentsBarnd: action.payload,
    }),
    [MY_CONTENTS]: (state, action) => ({
      ...state,
      contents: action.payload,
    }),
    [MY_CONTENTS_TOTAL]: (state, action) => ({
      ...state,
      contentsTotal: action.payload,
    }),
    [MY_CONTENTS_RECOMMENDATION]: (state, action) => ({
      ...state,
      contentsRecommendation: action.payload,
    }),
    [MY_CONTENTS_RECOMMENDATION_TOTAL]: (state, action) => ({
      ...state,
      contentsRecommendationTotal: action.payload,
    }),
  },
  initState
)
/* action api function */

// 마이렛미업
export const getMyLetmeupStatisticsAction = param => async dispatch => {
  const result = await MyApis.getMyLetmeupStatistics(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setMyLetmeup(result.data))
    return true
  } else {
    dispatch(setMyLetmeup({}))
    return false
  }
}
// 마이프로필
export const getMyProfileAction = param => async dispatch => {
  const result = await MyApis.getMyProfile(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setMyProfile(result.data.userProfile))
    return true
  } else {
    dispatch(setMyProfile({}))
    return false
  }
}

//키워드 순위
export const getMyLetmeupStatisticsKeywordAction = param => async dispatch => {
  const result = await MyApis.getMyLetmeupStatisticsKeyword(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setMyLetmeupKeywordRank(result.data.keywordStatistics))
    return true
  } else {
    dispatch(setMyLetmeupKeywordRank([]))
    return false
  }
}

//누적공부시간 순위
export const getMyLetmeupStatisticsStudyTimeAction = param => async dispatch => {
  const result = await MyApis.getMyLetmeupStatisticsStudyTime(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setMyLetmeupStudyRank(result.data.userStudyStatistics))
    return true
  } else {
    dispatch(setMyLetmeupStudyRank([]))
    return false
  }
}
//베스트 스터디 순위
export const getMyLetmeupStatisticsBestStudyAction = param => async dispatch => {
  const result = await MyApis.getMyLetmeupStatisticsBestStudy(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setMyLetmeupBestStudyRank(result.data.studyStatistics))
    return true
  } else {
    dispatch(setMyLetmeupBestStudyRank([]))
    return false
  }
}

//타겟 유저 정보
export const postTargetUserProfileAction = param => async dispatch => {
  const result = await UserApis.postUserProfile(param)
  const result2 = await UserApis.getUserStudiesParticipation(param)
  if (result.code == 0 || (result.code == 1 && result2.code == 0)) {
    let temp = result.data.userProfile
    temp.participatingStudies = result2.data.participatingStudies
    dispatch(setTargetUserProfile(temp))
    return true
  } else {
    dispatch(setMyLetmeupBestStudyRank({}))
    return false
  }
}

//컨설턴트 정보
export const getPartnersProfileAction = param => async dispatch => {
  const result = await MyApis.getPartnersProfile(param)
  if (result.code == 0 || result.code == 1) {
    let temp = []
    let arr = result.data.profile.todos
    for (let i = 0; i < arr.length; i++) {
      let temp2 = []
      if (i % 2 == 0) {
        for (let j = 0; j < 2; j++) {
          if (arr[i + j] != undefined) {
            temp2 = [...temp2, arr[i + j]]
          }
        }
      }
      if (temp2.length > 0) {
        temp = [...temp, temp2]
      }
    }
    dispatch(setConsultantProfile({ ...result.data.profile, webTodos: temp }))
    return true
  } else {
    dispatch(setConsultantProfile({}))
    return false
  }
}

// todo best list
export const getTodosBestAction = param => async dispatch => {
  const result = await MyApis.getTodosBest(param)
  if (result.code == 0 || result.code == 1) {
    let arr = result.data.todayTodosBest

    let temp = []
    for (let i = 0; i < arr.length; i++) {
      let temp2 = []
      if (i % 2 == 0) {
        for (let j = 0; j < 2; j++) {
          if (arr[i + j] != undefined) {
            temp2 = [...temp2, arr[i + j]]
          }
        }
      }
      if (temp2.length > 0) {
        temp = [...temp, temp2]
      }
    }
    dispatch(setMyTodosBestList({ web: temp, mo: arr }))
    return true
  } else {
    dispatch(setMyTodosBestList({ web: [], mo: [] }))
    return false
  }
}

// 내 공부 리스트
export const getMyParticipatingStudiesAction = param => async dispatch => {
  const result = await UserApis.getUserStudiesParticipation(param)
  if (result.code == 0 || result.code == 1) {
    let arr = result.data.participatingStudies

    let temp = []
    for (let i = 0; i < arr.length; i++) {
      let temp2 = []
      if (i % 2 == 0) {
        for (let j = 0; j < 2; j++) {
          if (arr[i + j] != undefined) {
            temp2 = [...temp2, arr[i + j]]
          }
        }
      }
      if (temp2.length > 0) {
        temp = [...temp, temp2]
      }
    }
    dispatch(setMyParticipatingStudiesListPc(temp))
    dispatch(setMyParticipatingStudiesList(result.data.participatingStudies))
    return true
  } else {
    dispatch(setMyParticipatingStudiesListPc([]))
    dispatch(setMyParticipatingStudiesList([]))
    return false
  }
}
// 오늘의 todo list
export const getTodayTodosAction = param => async dispatch => {
  const result = await MyApis.getTodayTodos(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setMyTodayTodos(result.data.todoAlarms))
    return true
  } else {
    dispatch(setMyTodayTodos([]))
    return false
  }
}
// 오늘의 todo 가져오기
export const postTodosByAlarmAction = param => async dispatch => {
  const result = await MyApis.postTodosByAlarm(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
// 내 todo list
export const getTodoTasksAction = param => async dispatch => {
  const result = await MyApis.getTodoTasks(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setMyTodoList(result.data.myTodos))
    return true
  } else {
    dispatch(setMyTodoList([]))
    return false
  }
}

// todo add
export const postTodoTaskAddAction = param => async dispatch => {
  const result = await MyApis.postTodoTaskAdd(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
// todo favorite
export const postTodoFavoriteAction = param => async dispatch => {
  const result = await MyApis.postTodoFavorite(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
// todo sava
export const postTodoSaveAction = param => async dispatch => {
  const result = await MyApis.postTodoSave(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

// todo edit
export const putTodoTaskEditAction = param => async dispatch => {
  const result = await MyApis.putTodoTaskEdit(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

// todo del
export const delTodoTaskAction = param => async dispatch => {
  const result = await MyApis.delTodoTask(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

// 내 누적 스터디
export const getMyStatisticsStudyimeMeAction = param => async dispatch => {
  const result = await MyApis.getMyStatisticsStudyimeMe(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setMyStatisticsStudytimeMe(result.data.myStatisticsStudyTime))
    return true
  } else {
    dispatch(setMyStatisticsStudytimeMe([]))
    return false
  }
}
//get ai 민감도 분석
export const getMyAnalysisAiAction = param => async dispatch => {
  console.log('getMyAnalysisAiAction', param)
  const result = await MyApis.getMyAnalysisAi(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setMyAnalysisAi(result.data))
    return true
  } else {
    dispatch(setMyAnalysisAi({}))
    return false
  }
}
//get ai 민감도 분석
export const getMyAnalysisRuleAction = param => async dispatch => {
  const result = await MyApis.getMyAnalysisRule(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setMyAnalysisRule(result.data.chart))
    return true
  } else {
    dispatch(setMyAnalysisRule({}))
    return false
  }
}
//get 뇌파 조회
export const getBrainMonitoringsAction = param => async dispatch => {
  const result = await MyApis.getBrainMonitorings(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setMyAnalysisBrain(result.data))
    return true
  } else {
    dispatch(setMyAnalysisBrain({}))
    return false
  }
}
//get 내 포인트
export const getPointMyAction = param => async dispatch => {
  const result = await MyApis.getPointMy(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setMyPoint(result.data.myPointInformation))
    return true
  } else {
    dispatch(setMyPoint({}))
    return false
  }
}
//get todo 전체보기 키워드
export const getKeywordRecentlyAction = param => async dispatch => {
  const result = await MyApis.getKeywordRecently(param)

  if (result.code == 0 || result.code == 1) {
    dispatch(setMyTodoKeywordList(result.data.keywords))

    return true
  } else {
    dispatch(setMyTodoKeywordList([]))

    return false
  }
}
//get todo 전체보기
export const getTodosAllAction = param => async dispatch => {
  const result = await MyApis.getTodosAll(param)

  if (result.code == 0 || result.code == 1) {
    let arr = result.data.todos
    // for (let j = 0; j < arr.length; j++) {
    //   let rnd = Math.floor(Math.random() * 4)
    //   let dumyData = TODODUMY[rnd]
    //   arr[j].partnerManagerName = dumyData.partnerManagerName
    //   arr[j].partnerManagerInfo1 = dumyData.partnerManagerInfo1
    //   arr[j].partnerManagerInfo2 = dumyData.partnerManagerInfo2
    //   arr[j].partnerProfileImage = dumyData.partnerProfileImage
    //   arr[j].partnerSeq = dumyData.partnerSeq
    // }
    dispatch(setMyTodoListAll(arr))
    dispatch(setMyTodoListAllTotal(result.data.totalCount))

    return true
  } else {
    dispatch(setMyTodoListAll([]))
    dispatch(setMyTodoListAllTotal(0))
    return false
  }
}
export const getTodosAllAddAction = param => async dispatch => {
  const result = await MyApis.getTodosAll(param)

  if (result.code == 0 || result.code == 1) {
    let arr = [...param.myTodoListAll, ...result.data.todos]
    // for (let j = 0; j < arr.length; j++) {
    //   let rnd = Math.floor(Math.random() * 4)
    //   let dumyData = TODODUMY[rnd]
    //   arr[j].partnerManagerName = dumyData.partnerManagerName
    //   arr[j].partnerManagerInfo1 = dumyData.partnerManagerInfo1
    //   arr[j].partnerManagerInfo2 = dumyData.partnerManagerInfo2
    //   arr[j].partnerProfileImage = dumyData.partnerProfileImage
    //   arr[j].partnerSeq = dumyData.partnerSeq
    // }
    dispatch(setMyTodoListAll(arr))
    dispatch(setMyTodoListAllTotal(result.data.totalCount))

    return true
  } else {
    dispatch(setMyTodoListAll([]))
    dispatch(setMyTodoListAllTotal(0))
    return false
  }
}
//get 내 포인트 내역
export const getPointLogsAction = param => async dispatch => {
  const result = await MyApis.getPointLogs(param)

  if (result.code == 0 || result.code == 1) {
    dispatch(setMyPointLog(result.data.pointLogs))
    dispatch(setMyPointLogTotal(result.data.totalCount))

    return true
  } else {
    dispatch(setMyPointLog([]))
    return false
  }
}
//get 내 포인트 내역 ++
export const getPointLogsAddAction = param => async dispatch => {
  const result = await MyApis.getPointLogs(param)
  if (result.code == 0 || result.code == 1) {
    let temp = param.myPointLog
    let temp2 = result.data.pointLogs
    dispatch(setMyPointLog([...temp, ...temp2]))
    dispatch(setMyPointLogTotal(result.data.totalCount))
    return true
  } else {
    dispatch(setMyPointLog({}))
    return false
  }
}

//get 내 결제 내역
export const getOrderLogAction = param => async dispatch => {
  const result = await MyApis.getOrderLog(param)

  if (result.code == 0 || result.code == 1) {
    let temp = result.data.orderLogs

    let arr = []
    let arr2 = []
    for (let i = 0; i < temp.length; i++) {
      if (i == 0) {
        arr2 = [temp[i]]
        if (temp.length == 1) {
          arr = [arr2]
        }
      } else if (temp.length - 1 == i) {
        if (moment(temp[i].date).format("YYYY-MM-DD") != moment(temp[i - 1].date).format("YYYY-MM-DD")) {
          arr = [...arr, arr2, [temp[i]]]
        } else {
          arr2 = [...arr2, temp[i]]
          arr = [...arr, arr2]
        }
      } else if (moment(temp[i].date).format("YYYY-MM-DD") != moment(temp[i - 1].date).format("YYYY-MM-DD")) {
        arr = [...arr, arr2]
        arr2 = [temp[i]]
      } else if (moment(temp[i].date).format("YYYY-MM-DD") == moment(temp[i - 1].date).format("YYYY-MM-DD")) {
        arr2 = [...arr2, temp[i]]
      }
    }
    dispatch(setMyOrderLog(arr))
    dispatch(setMyOrderLogTotal(result.data.count))
    dispatch(setMyOrderLogInfo(result.data.totalOrderInfomation))
    dispatch(setMyOrderLogNo(result.data.orderLogs.length > 0 ? result.data.orderLogs[result.data.orderLogs.length - 1].no : ""))
    return true
  } else {
    dispatch(setMyOrderLog([]))
    dispatch(setMyOrderLogTotal(0))
    dispatch(setMyOrderLogInfo({}))
    dispatch(setMyOrderLogNo(""))
    return false
  }
}
//get 내 포인트 내역 ++
export const getOrderLogAddAction = param => async dispatch => {
  const result = await MyApis.getOrderLog(param)

  if (result.code == 0 || result.code == 1) {
    // let temp = result.data.pointLogs
    let temp2 = param.myOrderLog
    let temp = result.data.orderLogs
    let arr = []
    let arr2 = []
    for (let i = 0; i < temp.length; i++) {
      if (i == 0) {
        arr2 = [temp[i]]
      } else if (temp.length - 1 == i) {
        if (moment(temp[i].date).format("YYYY-MM-DD") != moment(temp[i - 1].date).format("YYYY-MM-DD")) {
          arr = [...arr, arr2, [temp[i]]]
        } else {
          arr2 = [...arr2, temp[i]]
          arr = [...arr, arr2]
        }
      } else if (moment(temp[i].date).format("YYYY-MM-DD") != moment(temp[i - 1].date).format("YYYY-MM-DD")) {
        arr = [...arr, arr2]
        arr2 = [temp[i]]
      } else if (moment(temp[i].date).format("YYYY-MM-DD") == moment(temp[i - 1].date).format("YYYY-MM-DD")) {
        arr2 = [...arr2, temp[i]]
      }
    }
    dispatch(setMyOrderLog([...temp2, ...arr]))
    dispatch(setMyOrderLogTotal(result.data.count))
    dispatch(setMyOrderLogInfo(result.data.totalOrderInfomation))
    dispatch(setMyOrderLogNo(result.data.orderLogs.length > 0 ? result.data.orderLogs[result.data.orderLogs.length - 1].no : ""))
    return true
  } else {
    dispatch(setMyOrderLog([]))
    dispatch(setMyOrderLogTotal(0))
    dispatch(setMyOrderLogInfo({}))
    dispatch(setMyOrderLogNo(""))
    return false
  }
}
//get 공지사항
export const getNoticesAction = param => async dispatch => {
  const result = await MyApis.getNotices(param)

  if (result.code == 0 || result.code == 1) {
    dispatch(setMyNotices(result.data.notices))
    dispatch(setMyNoticesTotal(result.data.totalCount))

    return true
  } else {
    dispatch(setMyNotices([]))
    dispatch(setMyNoticesTotal(0))
    return false
  }
}
//get 공지사항 add
export const getNoticesAddAction = param => async dispatch => {
  const result = await MyApis.getNotices(param)

  if (result.code == 0 || result.code == 1) {
    dispatch(setMyNotices([...param.myNoticesList, ...result.data.notices]))
    dispatch(setMyNoticesTotal(result.data.totalCount))

    return true
  } else {
    dispatch(setMyNotices([]))
    dispatch(setMyNoticesTotal(0))
    return false
  }
}
//get 공지사항 상세
export const getNoticeAction = param => async dispatch => {
  const result = await MyApis.getNotice(param)

  if (result.code == 0 || result.code == 1) {
    dispatch(setMyNotice(result.data.notice))

    return true
  } else {
    dispatch(setMyNotice({}))
    return false
  }
}
//초대문자 get
export const getInvitationsAction = param => async dispatch => {
  let result = {}
  if (param == 0) {
    result = await MyApis.getInvitationsReceive(param)
  } else {
    result = await MyApis.getInvitationsSend(param)
  }
  if (result.code == 0 || result.code == 1) {
    dispatch(setMyInvitations(result.data.invitations))
    return true
  } else {
    dispatch(setMyInvitations([]))
    return false
  }
}
// 초대문자 del
export const delInvitationsAction = param => async dispatch => {
  const result = await MyApis.delInvitations(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

//컨텐츠 브랜드 목록
export const getContentsBrandNameAction = param => async dispatch => {
  const result = await MyApis.getContentsBrandName(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setMyContentsBrand(result.data.brandNameList))
    return true
  } else {
    dispatch(setMyContentsBrand([]))
    return false
  }
}
//추천 컨텐츠 목록
export const getContentsRecommendationAction = param => async dispatch => {
  const result = await MyApis.getContentsRecommendation(param)

  if (result.code == 0 || result.code == 1) {
    dispatch(setMyContentsRecommendation(result.data.contents))
    dispatch(setMyContentsRecommendationTotal(result.data.totalCount))

    return true
  } else {
    dispatch(setMyContentsRecommendation([]))
    dispatch(setMyContentsRecommendationTotal(0))
    return false
  }
}
//추천 컨텐츠 목록++
export const getContentsRecommendationAddAction = param => async dispatch => {
  const result = await MyApis.getContentsRecommendation(param)
  if (result.code == 0 || result.code == 1) {
    let temp = param.contents
    let temp2 = result.data.contents
    dispatch(setMyContentsRecommendation([...temp, ...temp2]))
    dispatch(setMyContentsRecommendationTotal(result.data.totalCount))
    return true
  } else {
    dispatch(setMyContentsRecommendation([]))
    dispatch(setMyContentsRecommendationTotal(0))
    return false
  }
}
//내컨텐츠 목록
export const getContentsMyAction = param => async dispatch => {
  const result = await MyApis.getContentsMy(param)

  if (result.code == 0 || result.code == 1) {
    dispatch(setMyContents(result.data.myContents))
    dispatch(setMyContentstotal(result.data.totalCount))

    return true
  } else {
    dispatch(setMyContents([]))
    dispatch(setMyContentstotal(0))
    return false
  }
}
//내컨텐츠 목록++
export const getContentsMyAddAction = param => async dispatch => {
  const result = await MyApis.getContentsRecommendation(param)
  if (result.code == 0 || result.code == 1) {
    let temp = param.myContents
    let temp2 = result.data.myContents
    dispatch(setMyContents([...temp, ...temp2]))
    dispatch(setMyContentstotal(result.data.totalCount))
    return true
  } else {
    dispatch(setMyContents([]))
    dispatch(setMyContentstotal(0))
    return false
  }
}
//내컨텐츠 추가
export const postContentsMyAction = param => async dispatch => {
  const result = await MyApis.postContentsMy(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else {
    return false
  }
}
//내컨텐츠 삭제
export const delContentsMyAction = param => async dispatch => {
  const result = await MyApis.delContentsMy(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else {
    return false
  }
}
