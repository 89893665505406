import { combineReducers } from "redux"
import sign from "./sign"
import user from "./user"
import popup from "./popup"
import studyMake from "./studyMake"
import intro from "./intro"
import config from "./config"
import exploring from "./exploring"
import my from "./my"
import jitsiInfo from "./jitsiInfo"
import payments from "./payments"

const rootReducer = combineReducers({
  sign,
  popup,
  studyMake,
  intro,
  config,
  exploring,
  my,
  jitsiInfo,
  user,
  payments,
})

export default rootReducer
