import { createAction, handleActions } from "redux-actions"
import StudyMakeApis from "../../apis/StudyMakeApis"

const SET_STUDY_MAKE_DATA = "studymake/set-study-make-data"
const SET_STUDY_MAKE_RESULT = "studymake/set-study-make-result"
const SET_STUDY_MAKE_RECOMMEND_PROGRAM = "studymake/set-study-make-recommend-program"
const SET_STUDY_MAKE_SETTING = "studymake/set-study-setting"
const SET_STUDY_MAKE_RESULT_INFO = "studymake/set-study-result-info"

export const setStudyMakeData = createAction(SET_STUDY_MAKE_DATA)
export const setStudyMakeResult = createAction(SET_STUDY_MAKE_RESULT)
export const setStudyMakeRecommendProgram = createAction(SET_STUDY_MAKE_RECOMMEND_PROGRAM)
export const setStudyMakeSetting = createAction(SET_STUDY_MAKE_SETTING)
export const setStudyMakeResultInfo = createAction(SET_STUDY_MAKE_RESULT_INFO)

const initState = {
  studyMakePopupData: {
    type: 0,
    passwordOn: false,
    password: "",
    studyName: "",
    keyword: [],
    startDate: "",
    endDate: "",
    isDetail: false,
    referralType: -1,
    detailTimeArr: [],
    isLoop: false,
    messageType: -1,
    messageText: "",
  },
  studyMakeResultData: {},
  studyMakeResultInfo: {},
  studyMakeRecommendList: [],
  studyMakeSetting: {},
}

export default handleActions(
  {
    [SET_STUDY_MAKE_DATA]: (state, action) => ({
      ...state,
      studyMakePopupData: action.payload,
    }),
    [SET_STUDY_MAKE_RESULT]: (state, action) => ({
      ...state,
      studyMakeResultData: action.payload,
    }),
    [SET_STUDY_MAKE_RECOMMEND_PROGRAM]: (state, action) => ({
      ...state,
      studyMakeRecommendList: action.payload,
    }),
    [SET_STUDY_MAKE_SETTING]: (state, action) => ({
      ...state,
      studyMakeSetting: action.payload,
    }),
    [SET_STUDY_MAKE_RESULT_INFO]: (state, action) => ({
      ...state,
      studyMakeResultInfo: action.payload,
    }),
  },
  initState
)

// 스터디 개설
export const postStudyMake = param => async dispatch => {
  const result = await StudyMakeApis.postStudyMakeRoom(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setStudyMakeResult(result.data.study))
    return result.data.study
  } else return false
}
// 스터디 시작
export const postStudyStart = param => async dispatch => {
  const result = await StudyMakeApis.postStudyRoomStart(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
//스터디 pw check
export const postStudyPasswordCheckAction = param => async dispatch => {
  const result = await StudyMakeApis.postStudyPasswordCheck(param)
  if (result.code == 0 || result.code == 1) {
    return result.data.checkStudyPassword
  } else return false
}
//스터디 비밀번호 변경
export const putStudyRoomSettingPasswordAction = param => async dispatch => {
  const result = await StudyMakeApis.putStudyRoomSettingPassword(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

//스터디 초기장치 변경
export const putStudyRoomSettingDeviceAction = param => async dispatch => {
  const result = await StudyMakeApis.putStudyRoomSettingDevice(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
//스터디 인원수 변경
export const putStudyRoomPersonnelAction = param => async dispatch => {
  const result = await StudyMakeApis.putStudyRoomPersonnel(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
//스터디 상벌점 변경
export const putStudyRoomSettingScoreAction = param => async dispatch => {
  const result = await StudyMakeApis.putStudyRoomSettingScore(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}
//스터디 ai 변경
export const putStudyRoomSettingAIAction = param => async dispatch => {
  const result = await StudyMakeApis.putStudyRoomSettingAI(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

//스터디 날짜 수정
export const putStudyRoomSettingDateAction = param => async dispatch => {
  const result = await StudyMakeApis.putStudyRoomSettingDate(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

//스터디 시간 수정
export const putStudyRoomSettingLoopAction = param => async dispatch => {
  const result = await StudyMakeApis.putStudyRoomSettingLoop(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else return false
}

//스터디 추천 프로그램 get
export const getRecommendProgramAction = param => async dispatch => {
  const result = await StudyMakeApis.getRecommendProgram(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setStudyMakeRecommendProgram(result.data.recommendPrograms))
    return true
  } else {
    dispatch(setStudyMakeRecommendProgram([]))

    return false
  }
}
//스터디 설정 get
export const getStudyRoomSettingAction = param => async dispatch => {
  const result = await StudyMakeApis.getStudyRoomSetting(param)
  if (result.code == 0 || result.code == 1) {
    dispatch(setStudyMakeSetting(result.data.studyRoomSettings))
    return true
  } else {
    dispatch(setStudyMakeSetting([]))

    return false
  }
}
//스터디 정보 get
export const getStudyRoomInfoAction = param => async dispatch => {
  let temp = {}
  const result = await StudyMakeApis.getStudyRoomInfo(param)
  temp = { ...result.data.study }
  const result2 = await StudyMakeApis.getStudyRoomLearnAttitude(param)
  temp = { ...temp, attitude: result2.data }
  const result3 = await StudyMakeApis.getStudyRoomAi(param)
  temp = { ...temp, ai: result3.data }
  if (result.code == 0 || (result.code == 1 && result2.code == 0 && result3.code == 0)) {
    dispatch(setStudyMakeResultInfo(temp))
    return true
  } else {
    dispatch(setStudyMakeResultInfo({}))

    return false
  }
}
//스터디 공개설정 put
export const putStudyRoomSettingSearchAction = param => async dispatch => {
  const result = await StudyMakeApis.putStudyRoomSettingSearch(param)
  if (result.code == 0 || result.code == 1) {
    return true
  } else {
    return false
  }
}
